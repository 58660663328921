import React, { forwardRef, useImperativeHandle } from 'react';
import { Avatar, Divider, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import axios from 'axios';
import * as dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
const ListFertilizers = forwardRef((props, ref) => {
    const [list, setList] = React.useState([]);
    const [group, setGroup] = React.useState([]);
    const [current, setCurrent] = React.useState(null);
    const [load, setLoad] = React.useState(false);
    useImperativeHandle(ref, () => ({
        update: update
    }));
    React.useEffect(() => {
        update();
    }, []);
    const update = () => {
        if (props.token) {
            setLoad(true);
            axios.get('/fertilizer/list', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    var items = responce.data;
                    var groups = [...new Set(items.map(item => item.fertilizer_culture.cultures_name))];
                    setGroup(groups);
                    setList(items);
                    setLoad(false);
                }
            });
        }
    };
    React.useEffect(() => {
        props.select(current);
    }, [current]);
    return (React.createElement(React.Fragment, null,
        load == true && (React.createElement(React.Fragment, null,
            React.createElement(LinearProgress, { color: "success" }),
            group.length == 0 && (React.createElement(Typography, { variant: 'caption', component: 'div', align: 'center', style: { color: 'grey', paddingTop: '16px' } }, "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u0441\u043F\u0438\u0441\u043A\u0430 ...")))),
        group.map((culture, index) => {
            return (React.createElement(List, { sx: { width: '100%', maxWidth: 360, bgcolor: 'background.paper' }, key: `fertilizer_group_${index}`, component: "nav", subheader: React.createElement(ListSubheader, { component: "div", id: "nested-list-subheader" }, culture) }, list.filter(c => c.fertilizer_culture.cultures_name == culture)
                .map((item) => (React.createElement("div", { key: `cont_${item.id}` },
                React.createElement(ListItem, { key: `l_${item.id}`, disablePadding: true },
                    React.createElement(ListItemButton, { selected: (current === null || current === void 0 ? void 0 : current.id) == item.id, onClick: () => setCurrent(item) },
                        React.createElement(ListItemAvatar, null,
                            React.createElement(Avatar, { src: `/fertilizer/image/${item.id}`, alt: item.fertilizer_field.name, sx: { backgroundColor: '#bdbdbd' }, imgProps: {
                                    style: {
                                        width: '28px',
                                        height: '28px'
                                    }
                                } })),
                        React.createElement(ListItemText, { primary: item.fertilizer_field.name.charAt(0).toUpperCase() + item.fertilizer_field.name.slice(1), secondary: `Сорт: ${item.fertilizer_grade.grade_name} - EY: ${item.fertilizer_ey}` }))),
                React.createElement(Divider, { key: `d_${item.id}`, variant: "fullWidth", component: "li" }))))));
        })));
});
export default ListFertilizers;
