import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import BarChartIcon from '@mui/icons-material/BarChart';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import StorageIcon from '@mui/icons-material/Storage';
import { Button, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DialogYesNo from './elements/DialodYesNo';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import ApiIcon from '@mui/icons-material/Api';
import ExtensionIcon from '@mui/icons-material/Extension';
import RouteIcon from '@mui/icons-material/Route';
import GrassIcon from '@mui/icons-material/BlurLinear';
import { forwardRef } from 'react';
import useToken from '../services/token';
import MacroOffIcon from '@mui/icons-material/MacroOff';
const drawerWidth = 240;
function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });
    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});
const DrawerHeader = styled('div')(({ theme }) => (Object.assign({ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: theme.spacing(0, 1) }, theme.mixins.toolbar)));
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => (Object.assign({ zIndex: theme.zIndex.drawer + 1, transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }) }, (open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
}))));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => (Object.assign(Object.assign({ width: drawerWidth, flexShrink: 0, whiteSpace: 'nowrap', boxSizing: 'border-box' }, (open && Object.assign(Object.assign({}, openedMixin(theme)), { '& .MuiDrawer-paper': openedMixin(theme) }))), (!open && Object.assign(Object.assign({}, closedMixin(theme)), { '& .MuiDrawer-paper': closedMixin(theme) })))));
const MiniDrawer = forwardRef((props, ref) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [username, setUserName] = React.useState('');
    const { token, parseJwt } = useToken();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (token) {
            const payload = parseJwt(token);
            if (payload) {
                setUserName(payload.username);
            }
        }
    }, []);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const logout = () => {
        window.localStorage.removeItem("token");
        window.location.href = "/login";
    };
    return (React.createElement(Box, { sx: { display: 'flex', height: '100%' } },
        React.createElement(ElevationScroll, { window: () => { return window; } },
            React.createElement(AppBar, { position: "fixed", open: open },
                React.createElement(Toolbar, null,
                    React.createElement(IconButton, { color: "inherit", "aria-label": "open drawer", onClick: handleDrawerOpen, edge: "start", sx: Object.assign({ marginRight: 5 }, (open && { display: 'none' })) },
                        React.createElement(MenuIcon, null)),
                    React.createElement(Typography, { variant: "h6", noWrap: true, component: "div", sx: { flexGrow: 1 } }, "Precision Agriculture Framework"),
                    username == ''
                        ?
                            (React.createElement(Skeleton, { width: 60 }))
                        :
                            (React.createElement(Typography, { variant: "button", display: "block", gutterBottom: true, sx: { marginTop: '6px' } }, username)),
                    React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true, sx: { marginLeft: '16px', marginRight: '8px', height: '25px', marginTop: '19px', borderColor: 'white' } }),
                    React.createElement(Button, { color: "inherit", onClick: () => setDialogOpen(true) }, "\u0412\u044B\u0445\u043E\u0434")))),
        React.createElement(Drawer, { variant: "permanent", open: open },
            React.createElement(DrawerHeader, null,
                React.createElement(IconButton, { onClick: handleDrawerClose }, theme.direction === 'rtl' ? React.createElement(ChevronRightIcon, null) : React.createElement(ChevronLeftIcon, null))),
            React.createElement(Divider, null),
            React.createElement(List, null,
                React.createElement(ListItem, { key: 'fields', disablePadding: true, sx: { display: 'block' }, onClick: () => navigate('/') },
                    React.createElement(ListItemButton, { sx: {
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        } },
                        React.createElement(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            } },
                            React.createElement(ShareLocationIcon, { color: props.type == 'fields' ? 'primary' : 'inherit' })),
                        React.createElement(ListItemText, { primary: 'Опытные участки', sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: 'nvdi', disablePadding: true, sx: { display: 'block' }, onClick: () => navigate('/ndvi') },
                    React.createElement(ListItemButton, { sx: {
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        } },
                        React.createElement(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            } },
                            React.createElement(ExtensionIcon, { color: props.type == 'nvdi' ? 'primary' : 'inherit' })),
                        React.createElement(ListItemText, { primary: 'NDVI', sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: 'weed', disablePadding: true, sx: { display: 'block' }, onClick: () => navigate('/weed') },
                    React.createElement(ListItemButton, { sx: {
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        } },
                        React.createElement(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            } },
                            React.createElement(MacroOffIcon, { color: props.type == 'weed' ? 'primary' : 'inherit' })),
                        React.createElement(ListItemText, { primary: 'Засоренность', sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: 'fertilizer', disablePadding: true, sx: { display: 'block' }, onClick: () => navigate('/fertilizer') },
                    React.createElement(ListItemButton, { sx: {
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        } },
                        React.createElement(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            } },
                            React.createElement(GrassIcon, { color: props.type == 'fertilizer' ? 'primary' : 'inherit' })),
                        React.createElement(ListItemText, { primary: 'Удобрения', sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(Divider, null),
                React.createElement(ListItem, { key: 'stat', disablePadding: true, sx: { display: 'block' }, onClick: () => navigate('/statistic') },
                    React.createElement(ListItemButton, { sx: {
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        } },
                        React.createElement(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            } },
                            React.createElement(BarChartIcon, { color: props.type == 'stat' ? 'primary' : 'inherit' })),
                        React.createElement(ListItemText, { primary: 'Статистика', sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: 'stat', disablePadding: true, sx: { display: 'block' }, onClick: () => navigate('/flight') },
                    React.createElement(ListItemButton, { sx: {
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        } },
                        React.createElement(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            } },
                            React.createElement(RouteIcon, { color: props.type == 'flight' ? 'primary' : 'inherit' })),
                        React.createElement(ListItemText, { primary: 'Полеты', sx: { opacity: open ? 1 : 0 } })))),
            React.createElement(Divider, null),
            React.createElement(List, null,
                React.createElement(ListItem, { key: 'storage', disablePadding: true, sx: { display: 'block' }, onClick: () => navigate('/storage') },
                    React.createElement(ListItemButton, { sx: {
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        } },
                        React.createElement(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            } },
                            React.createElement(StorageIcon, { color: props.type == 'storage' ? 'primary' : 'inherit' })),
                        React.createElement(ListItemText, { primary: 'Данные', sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: 'api', disablePadding: true, sx: { display: 'block' }, onClick: () => window.location.href = '/docs' },
                    React.createElement(ListItemButton, { sx: {
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        } },
                        React.createElement(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            } },
                            React.createElement(ApiIcon, { color: props.type == 'api' ? 'primary' : 'inherit' })),
                        React.createElement(ListItemText, { primary: 'Api Сервис', sx: { opacity: open ? 1 : 0 } }))))),
        React.createElement(Box, { component: "main", sx: {
                flexGrow: 1,
                p: 0, flex: '1 1 auto',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
            } },
            React.createElement(DrawerHeader, null),
            props.children),
        React.createElement(DialogYesNo, { title: '\u0412\u044B\u0445\u043E\u0434', question: '\u0412\u044B \u0445\u043E\u0442\u0438\u0442\u0435 \u0432\u044B\u0439\u0442\u0438 \u0438\u0437 \u0441\u0438\u0441\u0442\u0435\u043C\u044B?', yes: logout, no: (x) => setDialogOpen(x), open: dialogOpen })));
});
export default MiniDrawer;
