var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from "react";
import axios from "axios";
export const useUploadForm = (url) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [progress, setProgress] = useState(0);
    const destroyControls = () => {
        setProgress(0);
    };
    const uploadForm = (token, formData) => __awaiter(void 0, void 0, void 0, function* () {
        if (token === null) {
            return {
                status: 403,
                data: null
            };
        }
        setIsSuccess(false);
        let res = yield axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total !== undefined) {
                    const progress = (progressEvent.loaded / progressEvent.total) * 50;
                    setProgress(progress);
                }
            },
            onDownloadProgress: (progressEvent) => {
                if (progressEvent.total !== undefined) {
                    const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
                    setProgress(progress);
                }
            },
        });
        setIsSuccess(true);
        return {
            status: res.status,
            data: res.data
        };
    });
    return {
        uploadForm,
        destroyControls,
        isSuccess,
        progress
    };
};
