import React, { forwardRef, useImperativeHandle } from 'react';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import axios from 'axios';
import * as dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import RouteIcon from '@mui/icons-material/Route';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
const ListFlight = forwardRef((props, ref) => {
    const [list, setList] = React.useState([]);
    const [listGroup, setListGroup] = React.useState([]);
    const [current, setCurrent] = React.useState(null);
    const gruppedFormat = 'YYYY-MM-DD';
    useImperativeHandle(ref, () => ({
        update: update
    }));
    React.useEffect(() => {
        update();
    }, []);
    const update = () => {
        if (props.token) {
            axios.get('/flight/list', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let items = responce.data.map((i) => {
                        i.date_created = new Date(i.date_created);
                        return i;
                    });
                    let groups = items.map((i) => {
                        return dayjs(i.date_created).format(gruppedFormat);
                    });
                    setListGroup([...new Set(groups)]);
                    setList(items);
                }
            });
        }
    };
    React.useEffect(() => {
        props.select(current);
    }, [current]);
    return (React.createElement(React.Fragment, null, listGroup.map((g, li) => {
        return (React.createElement(List, { sx: { width: '100%', maxWidth: 360, bgcolor: 'background.paper' }, key: `li_${li}`, component: "nav", subheader: React.createElement(ListSubheader, { component: "div", id: "nested-list-subheader" }, dayjs(g).format('ll')) }, list.filter(x => dayjs(x.date_created).format(gruppedFormat) === g)
            .map((item) => (React.createElement("div", { key: `cont_${item.id}` },
            React.createElement(ListItem, { key: `l_${item.id}`, disablePadding: true },
                React.createElement(ListItemButton, { selected: (current === null || current === void 0 ? void 0 : current.id) == item.id, onClick: () => setCurrent(item) },
                    React.createElement(ListItemAvatar, null,
                        React.createElement(Avatar, { alt: item.name, sx: { backgroundColor: '#bdbdbd' }, imgProps: {
                                style: {
                                    width: '28px',
                                    height: '28px'
                                }
                            } },
                            React.createElement(RouteIcon, null))),
                    React.createElement(ListItemText, { primary: item.name.charAt(0).toUpperCase() + item.name.slice(1), secondary: `Точек пролета: ${item.flights_count}` }))),
            React.createElement(Divider, { key: `d_${item.id}`, variant: "fullWidth", component: "li" }))))));
    })));
});
export default ListFlight;
