import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import App from './client/app';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
let theme = createTheme({
    palette: {
        background: {
            default: '#424242',
        },
        primary: {
            main: '#127147'
        },
        secondary: {
            main: '#ffc107'
        }
    }
});
theme = responsiveFontSizes(theme);
const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(React.createElement(ThemeProvider, { theme: theme },
    React.createElement(Router, null,
        React.createElement(SnackbarProvider, { maxSnack: 7 },
            React.createElement(App, null)))));
