var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Vega } from 'react-vega';
import axios from 'axios';
function Chart(_a) {
    var props = __rest(_a, []);
    const [config, setConfig] = React.useState({});
    React.useEffect(() => {
        axios.get(`/statistic/config/${props.pages}?${Date.now()}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        })
            .then((responce) => {
            if (responce.data) {
                setConfig(responce.data);
            }
        })
            .catch(function (reason) {
        });
    }, [, props.pages]);
    return (React.createElement(Vega, { spec: config, actions: false }));
}
export default Chart;
