var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { Alert, Autocomplete, Divider, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from 'axios';
export default function AddFertilizerDialog(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const [field, setField] = React.useState(null);
    const [fields, setFields] = React.useState([]);
    const [culture, setCulture] = React.useState(null);
    const [cultures, setCultures] = React.useState([]);
    const [grade, setGrade] = React.useState(null);
    const [grades, setGrades] = React.useState([]);
    const [ey, setEy] = React.useState(0);
    const [nitrogenMax, setNitrogenMax] = React.useState(0);
    const [phosphor, setPhosphor] = React.useState(0);
    const [kalium, setKalium] = React.useState(0);
    const [threshold, setThreshold] = React.useState(0.3);
    const [error, setError] = React.useState(null);
    const [access, setAccess] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    React.useEffect(() => {
        if (props.token) {
            /** Поля */
            axios.get('/fertilizer/field/list', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let lds = responce.data.map(f => {
                        f.label = f.name;
                        return f;
                    });
                    setFields(lds);
                }
            });
            /** Культуры */
            axios.get('/culture/list?limit=50&offset=0', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let cltrs = responce.data.items.map(c => {
                        c.label = c.cultures_name;
                        return c;
                    });
                    setCultures(cltrs);
                }
            });
        }
    }, []);
    React.useEffect(() => {
        setAccess(field !== null
            && culture !== null
            && grade !== null
            && ey > 0
            && nitrogenMax > 0);
    }, [field, culture, grade, ey, nitrogenMax]);
    React.useEffect(() => {
        setGrades([]);
        if (culture) {
            axios.get(`/grades/list?limit=100&grade_culture_id=${culture.id}&&grade_nitrogen__gte=0`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let grds = responce.data.items.map(g => {
                        g.label = g.grade_name;
                        return g;
                    });
                    setGrades(grds);
                }
            });
        }
    }, [culture]);
    const calc_nitrogen_norm = (value) => {
        if (field !== null
            && culture !== null
            && grade !== null
            && value > 0
            && nitrogenMax == 0) {
            axios.post("/fertilizer/nitrogen/calc", JSON.stringify({
                field: field === null || field === void 0 ? void 0 : field.id,
                culture: culture === null || culture === void 0 ? void 0 : culture.id,
                grade: grade === null || grade === void 0 ? void 0 : grade.id,
                ey: value,
                phosphorus: 0,
                potassium: 0,
                nitrogenmax: 0,
                threshold: 0.3
            }), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((response) => {
                if (response.data) {
                    setNitrogenMax(Math.round((response.data + Number.EPSILON) * 100) / 100);
                    setPhosphor(40);
                    setKalium(40);
                }
            });
        }
    };
    const create = () => __awaiter(this, void 0, void 0, function* () {
        if (field !== null && culture !== null && grade !== null) {
            setProgress(true);
            axios.post("/fertilizer/create", JSON.stringify({
                field: field === null || field === void 0 ? void 0 : field.id,
                culture: culture === null || culture === void 0 ? void 0 : culture.id,
                grade: grade === null || grade === void 0 ? void 0 : grade.id,
                ey: ey,
                phosphorus: phosphor,
                potassium: kalium,
                nitrogenmax: nitrogenMax,
                threshold: threshold
            }), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((response) => {
                if (response.data.error !== null) {
                    setError(response.data.error);
                    destroy();
                    let error = response.data.error;
                    enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                }
                else {
                    destroy();
                    if (props.update != undefined) {
                        props.update();
                    }
                    props.setState(false);
                    enqueueSnackbar('Запись успешно добавлена', { variant: 'success', autoHideDuration: 1000 });
                }
            })
                .catch(function (reason) {
                let error = reason.response.data.detail || reason.message;
                setError(error);
                enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                destroy();
            });
        }
    });
    const destroy = () => __awaiter(this, void 0, void 0, function* () {
        setEy(0);
        setPhosphor(0);
        setKalium(0);
        setNitrogenMax(0);
        setField(null);
        setCulture(null);
        setGrade(null);
        setError(null);
        setProgress(false);
    });
    return (React.createElement("div", null,
        React.createElement(Dialog, { maxWidth: 'xs', open: props.open, BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            } },
            React.createElement(DialogTitle, null, "\u0420\u0430\u0441\u0441\u0447\u0438\u0442\u0430\u0442\u044C \u0443\u0434\u043E\u0431\u0440\u0435\u043D\u0438\u044F"),
            React.createElement(DialogContent, { sx: { minWidth: '396px' } },
                React.createElement(Alert, { severity: "warning", sx: { margin: '0px 16px' } }, "\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u044B \u043F\u043E\u043B\u044F \u0434\u043B\u044F \u043A\u043E\u0442\u043E\u0440\u044B\u0445 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u044B \u0434\u0430\u043D\u043D\u044B\u0435 \u0430\u0433\u0440\u043E\u0445\u0438\u043C\u0438\u0447\u0435\u0441\u043A\u043E\u0433\u043E \u0430\u043D\u0430\u043B\u0438\u0437\u0430, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0441\u043E\u0440\u0442\u0430 \u043A\u0443\u043B\u044C\u0442\u0443\u0440 \u0441 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u044B\u043C\u0438 \u043D\u043E\u0440\u043C\u0430\u043C\u0438."),
                React.createElement(Divider, { variant: "middle", sx: { marginTop: '16px' } }),
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(Autocomplete, { value: field, onChange: (event, newValue) => {
                            setField(newValue);
                        }, disablePortal: true, id: "combo-box-demo", options: fields, sx: { width: 'auto' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u041F\u043E\u043B\u0435" })) }),
                    React.createElement(Autocomplete, { value: culture, onChange: (event, newCulture) => {
                            setCulture(newCulture);
                        }, disablePortal: true, id: "combo-box-demo", options: cultures, sx: { width: 'auto' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u041A\u0443\u043B\u044C\u0442\u0443\u0440\u0430" })) }),
                    React.createElement(Autocomplete, { value: grade, onChange: (event, newGrade) => {
                            setGrade(newGrade);
                        }, disablePortal: true, disabled: grades.length == 0, id: "combo-box-demo", options: grades, sx: { width: 'auto' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u0421\u043E\u0440\u0442" })) }),
                    React.createElement(TextField, { value: ey, margin: "dense", id: "name", label: "\u041F\u043B\u0430\u043D\u0438\u0440\u0443\u044E\u043C\u0430\u044F \u0443\u0440\u043E\u0436\u0430\u0439\u043D\u043E\u0441\u0442\u044C (\u0446/\u0413\u0410)", fullWidth: true, type: 'number', variant: "filled", onBlur: (e) => calc_nitrogen_norm(Number(e.target.value)), onChange: (e) => setEy(Number(e.target.value)) })),
                React.createElement(Divider, { variant: "middle", sx: { marginTop: '16px' } }),
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(TextField, { sx: {
                            marginTop: '8px !important'
                        }, value: nitrogenMax, margin: "dense", id: "name", label: "\u041D\u043E\u0440\u043C\u0430 \u0430\u0437\u043E\u0442\u0430 (\u043A\u0433/\u0413\u0410)", fullWidth: true, type: 'number', variant: "filled", onChange: (e) => setNitrogenMax(Number(e.target.value)) }),
                    React.createElement(TextField, { value: phosphor, margin: "dense", id: "name", label: "\u041D\u043E\u0440\u043C\u0430 \u0444\u043E\u0441\u0444\u043E\u0440\u0430 (\u043A\u0433/\u0413\u0410)", fullWidth: true, type: 'number', variant: "filled", onChange: (e) => setPhosphor(Number(e.target.value)) }),
                    React.createElement(TextField, { value: kalium, margin: "dense", id: "name", label: "\u041D\u043E\u0440\u043C\u0430 \u043A\u0430\u043B\u0438\u044F (\u043A\u0433/\u0413\u0410)", fullWidth: true, type: 'number', variant: "filled", onChange: (e) => setKalium(Number(e.target.value)) }),
                    React.createElement(TextField, { value: threshold, margin: "dense", id: "threshold", label: "\u041F\u043E\u0440\u043E\u0433\u043E\u0432\u043E\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u0432\u0435\u0433\u0435\u0442\u0430\u0446\u0438\u043E\u043D\u043D\u043E\u0433\u043E \u0438\u043D\u0434\u0435\u043A\u0441\u0430", fullWidth: true, type: 'number', variant: "filled", onChange: (e) => setThreshold(Number(e.target.value)) }),
                    progress == true && (React.createElement(LinearProgress, null)))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: create, disabled: !access || progress == true }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C"),
                React.createElement(Button, { onClick: () => { props.setState(false); destroy(); } }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
