import React, { forwardRef, useImperativeHandle } from 'react';
import { MapContainer, TileLayer, LayersControl, FeatureGroup, Circle, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L, { latLng, latLngBounds } from 'leaflet';
import GeoJson from './GeoJson';
import { LinearProgress } from '@mui/material';
import { EditControl } from 'react-leaflet-draw';
import drawLocales from 'leaflet-draw-locales';
import axios from 'axios';
import { getMarkerIcon } from './MarkerIcon';
drawLocales('ru');
const { BaseLayer } = LayersControl;
const OpenStreetMaps = forwardRef((props, ref) => {
    const map = React.useRef(null);
    const geo = React.useRef(null);
    const feature = React.useRef(null);
    const [load, setLoad] = React.useState(false);
    const [json, setJson] = React.useState(null);
    const [center, setCenter] = React.useState({ lat: 49.9714, lng: 82.6059 });
    const [stantions, setStantions] = React.useState([]);
    const [filterId, setFilterId] = React.useState([]);
    useImperativeHandle(ref, () => ({
        setFly: Fly,
        setUrl: setUrl,
        invalidateSize: () => {
            var _a;
            (_a = map.current) === null || _a === void 0 ? void 0 : _a.invalidateSize();
        },
        setFilterId: setFilterId,
        setEditebleObject: setEditebleObject
    }));
    React.useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCenter({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            }, (error) => {
            });
        }
    }, []);
    React.useEffect(() => {
        if (map.current) {
            const gridLayer = L.gridLayer({}).addTo(map.current);
            map.current.on('resize', () => {
                gridLayer.redraw();
            });
        }
    }, [map.current]);
    React.useEffect(() => {
        if (props.setJson) {
            props.setJson(json);
        }
    }, [json]);
    React.useEffect(() => {
    }, [filterId]);
    React.useEffect(() => {
        if (props.showStantions) {
            axios.get(`/${props.showStantions}/list`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((response) => {
                if (response.data) {
                    let st_items = response.data.items.map((st) => {
                        let s = {
                            coord: { lat: st.stantions_latitude_center, lng: st.stantions_longitude_center },
                            radius: st.stantions_radius,
                            name: st.stantions_name,
                            distance: 0,
                            type: st.type || 'meteo'
                        };
                        return s;
                    });
                    setStantions(st_items);
                }
            });
        }
    }, [, props.showStantions]);
    React.useEffect(() => {
        var _a, _b;
        (_a = map.current) === null || _a === void 0 ? void 0 : _a.setView(center, (_b = map.current) === null || _b === void 0 ? void 0 : _b.getZoom());
    }, [center]);
    const Fly = (x, zoom) => {
        var _a;
        setCenter(x);
        if (zoom) {
            (_a = map.current) === null || _a === void 0 ? void 0 : _a.setZoom(zoom);
        }
    };
    const setUrl = (x, y) => {
        var _a, _b, _c, _d;
        if (x) {
            (_a = map.current) === null || _a === void 0 ? void 0 : _a.setView(y, (_b = map.current) === null || _b === void 0 ? void 0 : _b.getZoom());
            (_c = map.current) === null || _c === void 0 ? void 0 : _c.invalidateSize();
            (_d = geo.current) === null || _d === void 0 ? void 0 : _d.changeUrl(x, props.token, y);
        }
    };
    // Создадим объекты для редактирования
    // Используется в агрохимическом анализе
    const setEditebleObject = (url) => {
        if (url) {
            axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((response) => {
                var _a, _b, _c;
                if (response.data && feature.current) {
                    ;
                    const mapBounds = latLngBounds(response.data.features.map(point => latLng(point.geometry.coordinates[1], point.geometry.coordinates[0])));
                    (_a = map.current) === null || _a === void 0 ? void 0 : _a.setMaxBounds(mapBounds);
                    (_b = map.current) === null || _b === void 0 ? void 0 : _b.setZoom(((_c = map.current) === null || _c === void 0 ? void 0 : _c.getMaxZoom()) - 1);
                    response.data.features.map((f, index) => {
                        const options = {
                            radius: 5,
                            stroke: false,
                            fillColor: 'rgb(255, 193, 7)',
                            fillOpacity: 1,
                            id: f.properties.id || 0
                        };
                        const circleMarker = L.circleMarker([f.geometry.coordinates[1], f.geometry.coordinates[0]], options);
                        const popupContent = `
                                <div>
                                    <h3>ID: ${f.properties.id || 0}</h3>
                                    <div>Азот: ${f.properties.nitrogen}</div>
                                    <div>Фосфор: ${f.properties.phosphorus}</div>
                                    <div>Калий: ${f.properties.potassium}</div>
                                </div>
                            `;
                        circleMarker.bindPopup(popupContent);
                        circleMarker.addTo(feature.current);
                    });
                }
            });
        }
    };
    const createdMap = (e) => {
        if (feature.current) {
            const drawnItems = feature.current._layers;
            if (Object.keys(drawnItems).length > 1) {
                Object.keys(drawnItems).forEach((layerid, index) => {
                    if (index > 0)
                        return;
                    const layer = drawnItems[layerid];
                    feature.current.removeLayer(layer);
                });
            }
        }
        buildJson(e);
    };
    const editedMap = (e) => {
        buildJson(e);
    };
    const deletedMap = (e) => {
        setJson(null);
    };
    const buildJson = (e) => {
        var _a;
        const { layerType, layer } = e;
        if (layerType == "polygon") {
            layer.getLatLngs().map((ll) => {
                let coodinates = ll.map((coord) => {
                    return [
                        coord.lat,
                        coord.lng
                    ];
                });
                let json = {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: coodinates,
                    },
                    properties: {},
                };
                setJson(json);
            });
        }
        // Используется для редактирования Агрохимического анализа
        if (layerType == undefined && ((_a = props.editMode) === null || _a === void 0 ? void 0 : _a.remove) == false) {
            const { layers } = e;
            const markers = [];
            layers.getLayers().map((l) => {
                const id = l.options.id;
                const coords = l.getLatLng();
                markers.push({
                    id,
                    coords
                });
            });
            if (props.setEditResult !== undefined) {
                props.setEditResult(markers);
            }
        }
    };
    return (React.createElement("div", { style: { height: '100%', width: '100%', display: 'flex', flexDirection: 'column' } },
        load === true && (React.createElement(LinearProgress, { color: "success" })),
        React.createElement(MapContainer, { center: props.center || [49.9714, 82.6059], zoom: 13, ref: map, style: { width: '100%', height: '100%' } },
            props.editMode !== undefined && (React.createElement(FeatureGroup, { ref: feature },
                React.createElement(EditControl, { position: 'topleft', onCreated: createdMap, onEdited: editedMap, onDeleted: deletedMap, draw: {
                        rectangle: false,
                        polyline: false,
                        circle: false,
                        circlemarker: props.editMode.circlemarker == true ? {
                            allowIntersection: false,
                            edit: true,
                            showLength: false,
                            metric: false,
                            feet: true,
                            showArea: false
                        } : false,
                        marker: false,
                        polygon: props.editMode.polygon == true ? {
                            allowIntersection: false,
                            edit: false,
                            showLength: true,
                            metric: true,
                            feet: false,
                            showArea: true
                        } : false
                    }, edit: {
                        edit: true,
                        remove: props.editMode.remove
                    }, onDrawStart: (e) => {
                        if (json !== null) {
                            return false;
                        }
                    } }))),
            React.createElement(LayersControl, null,
                React.createElement(BaseLayer, { checked: props.layerIndex == undefined || true, name: "ArcGIS World - \u0441\u043F\u0443\u0442\u043D\u0438\u043A" },
                    React.createElement(TileLayer, { url: 'https://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}}/{y}/{x} ', maxZoom: 17, subdomains: ['server'] })),
                React.createElement(BaseLayer, { checked: props.layerIndex ? props.layerIndex == 1 : false, name: "Google map - \u0441\u043F\u0443\u0442\u043D\u0438\u043A" },
                    React.createElement(TileLayer, { url: 'https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&s=Ga&hl=ru', maxZoom: 20, subdomains: ['mt1', 'mt2', 'mt3'] })),
                React.createElement(BaseLayer, { checked: props.layerIndex ? props.layerIndex == 2 : false, name: "Map data \u00A9 OpenStreetMap - \u0440\u0435\u043B\u044C\u0435\u0444" },
                    React.createElement(TileLayer, { url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', maxZoom: 20 }))),
            stantions.length > 0 && (stantions.map((stantion, index) => {
                return (React.createElement(React.Fragment, null,
                    stantion.radius > 0 && (React.createElement(Circle, { key: `circle_${index}`, center: stantion.coord, radius: stantion.radius, color: 'rgba(254,231,218,89)', fillColor: 'rgba(254,231,218,89)' })),
                    React.createElement(Marker, { key: `marker_${index}`, icon: getMarkerIcon(stantion.type), position: stantion.coord },
                        React.createElement(Tooltip, { direction: "auto", offset: [0, 0], opacity: 1, permanent: props.tollTip == undefined ? false : props.tollTip }, stantion.name))));
            })),
            props.item > 0 && (React.createElement(GeoJson, { ref: geo, url: props.url, draw: props.draw, scipBorder: props.scipBorder, createBoundExtend: props.createBoundExtend, token: props.token, filterId: filterId, setLoad: setLoad })),
            props.customControl)));
});
export default OpenStreetMaps;
