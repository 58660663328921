var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ListAndView from './elements/ListAndView';
import RefreshIcon from '@mui/icons-material/Refresh';
import DialogYesNo from './elements/DialodYesNo';
import { useSnackbar } from 'notistack';
import AddFertilizerDialog from './fertilizer/Add';
import OpenStreetMaps from './map/OpenStreetMaps';
import LoupeIcon from '@mui/icons-material/Loupe';
import ListFertilizers from './fertilizer/List';
import FertilizerInfoPanel from './fertilizer/Fertilizer.Info';
import axios from 'axios';
import { darkBluenTheme, orangeTheme } from '../themes/NdviTheme';
import { useTheme } from '@mui/material/styles';
import Variants from './fertilizer/Variants';
function Fertilizers(_a) {
    var props = __rest(_a, []);
    const current_theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const map = React.useRef(null);
    const list = React.useRef(null);
    const panel = React.useRef(null);
    const variants = React.useRef(null);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [selectedElement, setSelectedElement] = React.useState(null);
    const [addDialog, setAddDialog] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [mapIndex, setMapIndex] = React.useState(0);
    const bands = [
        { url: '/fertilizer/map/4/false/', chart: true, id: 4 },
        { url: '/fertilizer/map/6/false/', chart: true, id: 6 },
        { url: '/fertilizer/map/7/false/', chart: true, id: 7 },
        { url: '/fertilizer/map/2/false/', chart: true, id: 2 },
        { url: '/fertilizer/map/3/false/', chart: true, id: 3 },
        { url: '/fertilizer/map/5/false/', chart: true, id: 5 },
        { url: '/fertilizer/map/1/false/', chart: true, id: 1 },
    ];
    React.useEffect(() => {
        var _a, _b;
        if (map.current) {
            if (selectedElement) {
                let latlng = { lat: 49.9714, lng: 82.6059 };
                if (selectedElement.fertilizer_field.latitude_center
                    && selectedElement.fertilizer_field.longitude_center) {
                    if (selectedElement.fertilizer_field.latitude_center > 1
                        && selectedElement.fertilizer_field.longitude_center > 1) {
                        latlng = {
                            lat: (_a = selectedElement.fertilizer_field) === null || _a === void 0 ? void 0 : _a.longitude_center,
                            lng: (_b = selectedElement.fertilizer_field) === null || _b === void 0 ? void 0 : _b.latitude_center
                        };
                    }
                }
                map.current.setUrl(`${bands[mapIndex].url}${selectedElement.id}`, latlng);
                map.current.setFilterId([]);
            }
            setSelectedTab(0);
            if (panel.current) {
                panel.current.setCurrentTab(0);
            }
        }
    }, [selectedElement]);
    React.useEffect(() => {
        var _a, _b, _c;
        if (map.current) {
            if (selectedElement) {
                let latlng = { lat: 49.9714, lng: 82.6059 };
                if (selectedElement.fertilizer_field.latitude_center
                    && selectedElement.fertilizer_field.longitude_center) {
                    if (selectedElement.fertilizer_field.latitude_center > 1
                        && selectedElement.fertilizer_field.longitude_center > 1) {
                        latlng = {
                            lat: (_a = selectedElement.fertilizer_field) === null || _a === void 0 ? void 0 : _a.longitude_center,
                            lng: (_b = selectedElement.fertilizer_field) === null || _b === void 0 ? void 0 : _b.latitude_center
                        };
                    }
                }
                map.current.setUrl(`${bands[mapIndex].url}${selectedElement.id}`, latlng);
                map.current.setFilterId([]);
                (_c = panel.current) === null || _c === void 0 ? void 0 : _c.setBand(bands[mapIndex].id);
            }
            if (variants.current) {
                variants.current.setValue(mapIndex);
            }
        }
    }, [mapIndex]);
    React.useEffect(() => {
        setMapIndex(selectedTab);
    }, [selectedTab]);
    const updateListItems = () => {
        var _a;
        (_a = list.current) === null || _a === void 0 ? void 0 : _a.update();
    };
    const deleteListItem = (x) => {
        if (selectedElement !== null) {
            axios.delete(`/fertilizer/delete/${selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                var _a, _b;
                if (responce.data) {
                    if (responce.data.success) {
                        setSelectedElement(null);
                        setDeleteDialog(false);
                        (_a = list.current) === null || _a === void 0 ? void 0 : _a.update();
                        (_b = map.current) === null || _b === void 0 ? void 0 : _b.invalidateSize();
                    }
                }
            });
        }
    };
    const filter = (items) => {
        var _a;
        (_a = map.current) === null || _a === void 0 ? void 0 : _a.setFilterId(items);
    };
    const get_variants = (tab) => {
        if (tab == 0) {
            return [
                { index: 0, title: 'По данным NDVI', disabled: false },
                { index: 5, title: 'По агрохимическому анализу', disabled: false },
                { index: 6, title: 'Фактические значения', disabled: false, devider: true }
            ];
        }
        else if (tab == 1) {
            return [
                { index: 1, title: 'По данным NDVI', disabled: false },
                { index: 3, title: 'Фактические значения', disabled: false, devider: true }
            ];
        }
        else if (tab == 2) {
            return [
                { index: 2, title: 'По данным NDVI', disabled: false },
                { index: 4, title: 'Фактические значения', disabled: false, devider: true }
            ];
        }
        else {
            return [];
        }
    };
    const get_theme_by_tab = (tab) => {
        if (tab == 1) {
            return darkBluenTheme;
        }
        else if (tab == 2) {
            return orangeTheme;
        }
        else {
            return current_theme;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 2, sx: { padding: '0px', flex: '1 1 auto', pt: '20px' } },
            React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", sx: { pl: 3 } },
                React.createElement(Link, { underline: "hover", color: "inherit", href: "/" }, "Agriculture"),
                React.createElement(Typography, { color: "text.primary" }, "\u0423\u0434\u043E\u0431\u0440\u0435\u043D\u0438\u044F")),
            React.createElement(Divider, null),
            React.createElement(ListAndView, { left: React.createElement(React.Fragment, null,
                    React.createElement("div", { style: {
                            backgroundColor: '#fafafa',
                            height: '52px',
                            borderBottom: '1px solid #eaeaea',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0px 16px'
                        } },
                        React.createElement(Tooltip, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" },
                            React.createElement(IconButton, { color: "success", onClick: () => setAddDialog(true) },
                                React.createElement(LoupeIcon, null))),
                        React.createElement(Tooltip, { title: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C" },
                            React.createElement(IconButton, { onClick: () => updateListItems() },
                                React.createElement(RefreshIcon, null)))),
                    React.createElement(Box, { sx: {
                            height: 'calc(100vh - 180px)',
                            overflowY: 'scroll',
                            "&::-webkit-scrollbar": {
                                width: 8
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#F1F1F1"
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#C1C1C1",
                                borderRadius: 2
                            }
                        } },
                        React.createElement(ListFertilizers, { ref: list, token: props.token, select: setSelectedElement }))), view: React.createElement(Grid, { container: true, spacing: 0, sx: { marginTop: '0px !important', height: '100%' } },
                    selectedElement !== null
                        &&
                            (React.createElement(Grid, { item: true, xs: "auto", style: { display: 'flex' } },
                                React.createElement(FertilizerInfoPanel, { ref: panel, token: props.token, id: selectedElement.id, extendChartData: bands[mapIndex].chart, variants: React.createElement("div", { style: { marginTop: '8px' } },
                                        React.createElement(Variants, { ref: variants, item: selectedElement, items: get_variants(selectedTab), theme: get_theme_by_tab(selectedTab), selected: 0, change: setMapIndex })), delete: (x) => setDeleteDialog(true), filterMap: filter, changeTab: setSelectedTab }))),
                    React.createElement(Grid, { item: true, xs: true },
                        React.createElement(OpenStreetMaps, { ref: map, token: props.token, url: null, draw: true, scipBorder: true, createBoundExtend: true, item: selectedElement == null ? 0 : selectedElement.fertilizer_field_id }))) })),
        React.createElement(AddFertilizerDialog, { token: props.token, open: addDialog, update: updateListItems, setState: setAddDialog }),
        selectedElement !== null && (React.createElement(DialogYesNo, { title: '\u0423\u0434\u0430\u043B\u0435\u043D\u0438\u0435', question: `Вы хотите удалить без возможности восстановления?`, open: deleteDialog, yes: () => deleteListItem(selectedElement.id), no: setDeleteDialog }))));
}
export default Fertilizers;
