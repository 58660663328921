import { Typography } from '@mui/material';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import axios from "axios";
import useToken from '../services/token';
const Login = () => {
    const { token, removeToken, setToken } = useToken();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const handleKeyDown = (key) => {
        if (key === 'Enter') {
            sign();
        }
    };
    const sign = () => {
        if ((userName == "") && (password == "")) {
            return;
        }
        else {
            let data = new FormData();
            data.append('username', userName);
            data.append('password', password);
            axios({
                method: "post",
                url: "/login",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                if (response.data.access_token) {
                    setToken(response.data.access_token);
                    window.location.href = "/";
                }
                if (response.data.message) {
                    setError(response.data.message);
                }
            })
                .catch(function (error) {
                setError(error);
            });
        }
    };
    return (React.createElement("div", { id: "fullPage" },
        React.createElement("div", { id: "brandingWrapper", className: "float" },
            React.createElement("div", { id: "branding", className: "illustrationClass" })),
        React.createElement("div", { id: "contentWrapper", className: 'float' },
            React.createElement("div", { id: "content" },
                React.createElement("div", { id: "header" },
                    React.createElement(Typography, { variant: 'h4' }, "Agriculture")),
                React.createElement("div", { id: "workArea" },
                    React.createElement("div", { style: { marginBottom: '30px' } },
                        React.createElement("div", null,
                            React.createElement("div", { style: { marginBottom: '30px' } },
                                React.createElement(Typography, { variant: 'body1' }, "\u0412\u044B\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u0432\u0445\u043E\u0434, \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u044F \u0443\u0447\u0435\u0442\u043D\u0443\u044E \u0437\u0430\u043F\u0438\u0441\u044C \u043E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u0438.")),
                            React.createElement("div", { id: "error", className: "fieldMargin error smallText", style: { display: error == null ? 'none' : 'block' } },
                                React.createElement(Typography, { variant: 'caption', id: "errorText" }, error)),
                            React.createElement("div", null,
                                React.createElement(Stack, { spacing: 1 },
                                    React.createElement(TextField, { required: true, id: "outlined-required", label: "\u0418\u043C\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F", onChange: (e) => setUserName(e.target.value), onKeyDown: (e) => handleKeyDown(e.key) }),
                                    React.createElement(TextField, { required: true, id: "outlined-password-input", label: "\u041F\u0430\u0440\u043E\u043B\u044C", type: "password", autoComplete: "current-password", onKeyDown: (e) => handleKeyDown(e.key), onChange: (e) => setPassword(e.target.value) })),
                                React.createElement("div", { className: "submitMargin" },
                                    React.createElement(Button, { onClick: () => sign(), variant: "contained" }, "\u0412\u0445\u043E\u0434"))))))),
            React.createElement("div", { id: "footer" },
                React.createElement("div", { id: "footerLinks" },
                    React.createElement(Typography, { variant: 'caption' }, "\u0412\u041A\u0423 \u0438\u043C. \u0421.\u0410\u043C\u0430\u043D\u0436\u043E\u043B\u043E\u0432\u0430"))))));
};
export default Login;
