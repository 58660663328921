var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack } from '@mui/system';
import { Chip, Divider, Typography } from '@mui/material';
export default function DialogYesNo(_a) {
    var _b;
    var props = __rest(_a, []);
    const [open, setOpen] = React.useState(props.open);
    return (React.createElement("div", null,
        React.createElement(Dialog, { BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            }, open: props.open, maxWidth: 'sm', onClose: props.no },
            React.createElement(DialogTitle, null, props.title),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null, props.question),
                props.description && ((_b = props.description) === null || _b === void 0 ? void 0 : _b.values.length) > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, { sx: { marginTop: '16px' } }),
                    React.createElement(DialogContent, { sx: { padding: '20px 0px' } },
                        React.createElement(Stack, { spacing: 2 },
                            React.createElement(Typography, { variant: "caption" }, props.description.title),
                            React.createElement(Box, null,
                                React.createElement(Stack, { direction: 'row', spacing: 2 }, props.description.values.map((v) => (React.createElement(Chip, { label: v, sx: { border: '1px dashed', backgroundColor: 'white', borderRadius: '6px' } })))))))))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: props.yes }, "\u0414\u0430"),
                React.createElement(Button, { onClick: () => props.no(false), autoFocus: true }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
