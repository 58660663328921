var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack } from '@mui/system';
import { Divider, LinearProgress, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import OpenStreetMaps from '../map/OpenStreetMaps';
import axios from 'axios';
import { useSnackbar } from 'notistack';
export default function AddFieldWithCompanyDialog(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const map = React.useRef(null);
    const [company, setCompany] = React.useState(null);
    const [companies, setCompanies] = React.useState([]);
    const [name, setName] = React.useState('');
    const [search, setSearch] = React.useState('');
    const [error, setError] = React.useState(null);
    const [access, setAccess] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [selectedElement, setSelectedElement] = React.useState(0);
    const [center, setCenter] = React.useState({ lat: 49.9714, lng: 82.6059 });
    const [json, setJson] = React.useState(null);
    React.useEffect(() => {
        get_companies();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCenter({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            }, (error) => {
            });
        }
    }, []);
    React.useEffect(() => {
        setAccess(json === null || name.length == 0);
    }, [json, name]);
    React.useEffect(() => {
        if (company !== null) {
            setCenter({ lat: company.latitude, lng: company.longitude });
            if (name == '') {
                let fullname = company.name.split(',')[0];
                setName(fullname);
            }
        }
    }, [company]);
    React.useEffect(() => {
        if (map.current) {
            map.current.setFly(center, 13);
        }
    }, [center]);
    React.useEffect(() => {
        if (search.length > 3) {
            get_companies(search == '' ? undefined : search);
        }
        if (search == '') {
            get_companies();
        }
    }, [search]);
    const get_companies = (search) => {
        axios.get(`/field/company/list?limit=100${search ? '&name__like=' + search : ''}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        })
            .then((response) => {
            if (response.data) {
                setCompanies(response.data.items.map((x) => {
                    x.label = x.name;
                    return x;
                }));
            }
        });
    };
    const create = () => __awaiter(this, void 0, void 0, function* () {
        if (json !== null
            && name !== '') {
            try {
                json.id = 0;
                let geo = {
                    type: "FeatureCollection",
                    features: [
                        json
                    ]
                };
                let build = JSON.parse(JSON.stringify(geo));
                if (build.features.length > 0) {
                    let coords = build.features[0].geometry.coordinates.map((c) => {
                        c = [c[1], c[0]];
                        return c;
                    });
                    build.features[0].geometry.coordinates = [
                        coords
                    ];
                }
                const formData = new FormData();
                formData.append("name", name);
                formData.append("geo", JSON.stringify(build));
                setProgress(true);
                axios.post("/field/create_with_map", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${props.token}`
                    }
                })
                    .then((response) => {
                    if (response.data.error !== null) {
                        setError(response.data.error);
                        let error = response.data.error;
                        enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                        destroy();
                    }
                    else {
                        destroy();
                        if (props.update != undefined) {
                            props.update();
                        }
                        props.setState(false);
                        enqueueSnackbar('Поле успешно добавлена', { variant: 'success', autoHideDuration: 1000 });
                    }
                })
                    .catch(function (reason) {
                    let error = reason.response.data.detail || reason.message;
                    setError(error);
                    enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                });
            }
            catch (e) {
                alert(e);
            }
        }
    });
    const destroy = () => __awaiter(this, void 0, void 0, function* () {
        setError(null);
        setName('');
        setProgress(false);
    });
    return (React.createElement("div", null,
        React.createElement(Dialog, { maxWidth: 'md', open: props.open, BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            } },
            React.createElement(DialogTitle, null, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u043B\u0435"),
            React.createElement(DialogContent, null,
                React.createElement(Box, { sx: { padding: '16px' } },
                    React.createElement(Typography, { color: "text.secondary", variant: "body2" }, "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u043F\u043E\u043B\u044F \u0434\u043B\u044F \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u044F \u043D\u043E\u0432\u043E\u0433\u043E \u043F\u043E\u043B\u044F \u0432 \u0441\u0438\u0441\u0442\u0435\u043C\u0435")),
                React.createElement(Divider, { variant: "middle" }),
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(Autocomplete, { value: company, onChange: (e, newValue) => {
                            setCompany(newValue);
                        }, onInputChange: (event, newInputValue) => {
                            setSearch(newInputValue);
                        }, disablePortal: true, id: "combo-box-demo", options: companies, sx: { width: 'auto' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u041F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u0435" })) }),
                    React.createElement(TextField, { margin: "dense", id: "name", label: "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435", fullWidth: true, variant: "filled", inputProps: {
                            maxLength: props.maxLength
                        }, value: name, onChange: (e) => setName(e.target.value) }),
                    React.createElement(Box, { component: "span", sx: { p: 0, border: '1px dashed grey', display: 'flex', width: '700px', height: '480px' } },
                        React.createElement(OpenStreetMaps, { ref: map, token: props.token, url: '/field/get/1', center: center, editMode: {
                                polygon: true,
                                circlemarker: false,
                                remove: true
                            }, setJson: setJson, item: selectedElement })),
                    progress == true && (React.createElement(LinearProgress, null)))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { disabled: access || progress, onClick: () => create() }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C"),
                React.createElement(Button, { disabled: progress, onClick: () => props.setState(false) }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
