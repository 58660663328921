import { createTheme } from '@mui/material/styles';
export const darkBluenTheme = createTheme({
    palette: {
        primary: {
            main: "rgb(19, 95, 167)"
        }
    }
});
export const blueTheme = createTheme({
    palette: {
        primary: {
            main: '#b2dfdb'
        }
    }
});
export const greenTheme = createTheme({
    palette: {
        primary: {
            main: '#127147'
        }
    }
});
export const orangeTheme = createTheme({
    palette: {
        primary: {
            main: '#ef6c00'
        }
    }
});
export const redTheme = createTheme({
    palette: {
        primary: {
            main: '#d32f2f'
        }
    }
});
export const yellowTheme = createTheme({
    palette: {
        primary: {
            main: '#827717'
        }
    }
});
export const yellowGreenTheme = createTheme({
    palette: {
        primary: {
            main: '#689f38'
        }
    }
});
