import { AppBar, Avatar, Box, Button, Card, CardActions, CardContent, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material';
import axios from 'axios';
import React, { useImperativeHandle, forwardRef } from 'react';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import DialogJsonViewer from '../elements/JsonViewer';
import TabPanel from '../elements/TabPanel';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
const FieldInfoPanel = forwardRef((props, ref) => {
    const [value, setValue] = React.useState(0);
    const [detail, setDetail] = React.useState(null);
    const [cutures, setCultures] = React.useState([]);
    const [jsonDialog, setJsonDialog] = React.useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useImperativeHandle(ref, () => ({}));
    React.useEffect(() => {
        if (props.token) {
            axios.get(`/field/detail/${props.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let detail = responce.data;
                    let stant_arr = detail.stantions.map((s) => {
                        let stantion = {
                            coord: {
                                lat: s.stantions_latitude_center,
                                lng: s.stantions_longitude_center
                            },
                            name: s.stantions_name,
                            radius: s.stantions_radius,
                            distance: s.distance,
                            type: 'meteo'
                        };
                        return stantion;
                    });
                    detail.stantions = stant_arr;
                    setDetail(detail);
                }
            });
            axios.get(`/plot/list?field__id=${props.id}&limit=50&offset=0`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let detail = responce.data.items;
                    let cultures = detail.map((x) => {
                        return { name: x['plot_culture']['cultures_name'], grade: x['plot_grade']['grade_name'], code: x['plot_culture']['cultures_code'] };
                    });
                    const result = Object.values(cultures.reduce((acc, obj) => (Object.assign(Object.assign({}, acc), { [obj.name]: obj })), {}));
                    setCultures(result);
                }
            });
        }
    }, [, props.id]);
    const toShp = () => {
        if (props.id) {
            window.location.href = `/field/shp/${props.id}`;
        }
    };
    return (React.createElement(Box, { sx: { width: '350px', flex: '1 1 auto', display: 'flex', flexDirection: 'column' } },
        detail !== null && (React.createElement(Card, { sx: { minWidth: 275, border: 'none', boxShadow: 'none', borderRadius: 0, display: 'flex', flexDirection: 'column', flex: '1 1 auto' } },
            React.createElement(CardContent, { sx: { pt: '32px', color: 'white', backgroundColor: 'rgb(18, 113, 71)' } },
                React.createElement(Typography, { variant: 'h5', component: 'div', sx: { fontWeight: 200 } }, detail.name),
                React.createElement(Typography, { sx: { fontWeight: 200, mb: 1.5, opacity: 0.6 } }, dayjs(detail.created).format('LLLL')),
                React.createElement(Stack, { direction: 'row', spacing: 1, sx: { alignItems: 'center', position: 'relative' } },
                    React.createElement(Typography, { variant: "h2", gutterBottom: true }, detail.hkr.toFixed(2)),
                    React.createElement(Typography, { variant: "h4", gutterBottom: true, sx: { opacity: 0.6 } }, "\u0433\u0430"),
                    detail.from_file == false && (React.createElement(Chip, { label: "shp", size: "small", sx: { position: 'absolute', right: '0px', color: 'white', opacity: '0.8' } })))),
            React.createElement(Box, { sx: { width: '100%', bgcolor: 'background.paper' } },
                React.createElement(AppBar, { position: "relative", sx: { boxShadow: 'none' } },
                    React.createElement(Tabs, { value: value, onChange: handleChange, textColor: "inherit", indicatorColor: "secondary" },
                        React.createElement(Tab, { label: "\u041E\u0431\u0449\u0430\u044F" }),
                        React.createElement(Tab, { label: "\u0421\u0442\u0430\u043D\u0446\u0438\u0438" }),
                        React.createElement(Tab, { label: "\u041A\u0443\u043B\u044C\u0442\u0443\u0440\u044B" })))),
            React.createElement(TabPanel, { value: value, index: 0 },
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u041A\u043E\u043E\u0440\u0434\u0438\u043D\u0430\u0442\u044B"),
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                            React.createElement(Typography, { variant: 'overline', style: { width: '80px', color: 'grey' }, gutterBottom: true }, "\u0428\u0438\u0440\u043E\u0442\u0430")),
                        React.createElement(Grid, { item: true, xs: true },
                            React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, detail.lat.toFixed(6)))),
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                            React.createElement(Typography, { variant: 'overline', style: { width: '80px', color: 'grey' }, gutterBottom: true }, "\u0414\u043E\u043B\u0433\u043E\u0442\u0430")),
                        React.createElement(Grid, { item: true, xs: true },
                            React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, detail.lng.toFixed(6)))),
                    detail.alt && (React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                            React.createElement(Typography, { variant: 'overline', style: { width: '80px', color: 'grey' }, gutterBottom: true }, "\u0412\u044B\u0441\u043E\u0442\u0430")),
                        React.createElement(Grid, { item: true, xs: true },
                            React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, detail.alt))))),
                React.createElement("div", { style: { marginTop: '16px' } },
                    React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u0420\u0430\u0437\u043C\u0435\u0440"),
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                            React.createElement(Typography, { variant: 'overline', style: { width: '80px', color: 'grey' }, gutterBottom: true }, "\u041A\u043C\u00B2")),
                        React.createElement(Grid, { item: true, xs: true },
                            React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `~${detail.km.toFixed(2)}`))))),
            React.createElement(TabPanel, { value: value, index: 1, padding: '0px' },
                React.createElement(List, { sx: { width: '100%', maxWidth: 350, bgcolor: 'background.paper' } }, detail.stantions.map((s, index) => {
                    return (React.createElement(ListItem, { key: `stantion_${index}_${s.name}` },
                        React.createElement(ListItemAvatar, null,
                            React.createElement(Avatar, null,
                                React.createElement(OnlinePredictionIcon, null))),
                        React.createElement(ListItemText, { primary: s.name, secondary: `Дистранция: ${s.distance.toFixed(2)} km` })));
                }))),
            React.createElement(TabPanel, { value: value, index: 2, padding: '0px' },
                React.createElement(List, { sx: { width: '100%', maxWidth: 350, bgcolor: 'background.paper' } }, cutures.map((culture, index) => {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(ListItem, { key: `culture_${index}_${culture.name}` },
                            React.createElement(ListItemAvatar, null,
                                React.createElement(Avatar, { src: `/static/images/culture_${culture.code}.svg` })),
                            React.createElement(ListItemText, { primary: culture.name, secondary: `Сорт: ${culture.grade}` })),
                        React.createElement(Divider, { component: "li" })));
                }))),
            React.createElement(CardActions, { style: { margin: '0px 16px' } },
                React.createElement(Button, { size: "small", onClick: () => setJsonDialog(true) }, "Geo JSON"),
                React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true, sx: { marginLeft: '8px' } }),
                React.createElement(Button, { size: "small", onClick: () => toShp() }, "SHP"),
                React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true, sx: { marginLeft: '8px' } }),
                React.createElement(Button, { size: "small", color: "error", disabled: detail.can_delete, onClick: () => props.delete(props.id, detail.relations) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")))),
        React.createElement(DialogJsonViewer, { token: props.token, url: `/field/geo/${props.id}`, open: jsonDialog, title: "GeoJson", close: setJsonDialog })));
});
export default FieldInfoPanel;
