var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DateFiled from '../elements/DateFiled';
import { Box, Stack } from '@mui/system';
import { CircularProgress, Divider, LinearProgress, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import { useSnackbar } from 'notistack';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
export default function AddWithGoogleDialog(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const [year, setYear] = React.useState(2023);
    const [field, setField] = React.useState(null);
    const [collection, setCollection] = React.useState({
        id: 1,
        collection_name: 'COPERNICUS/S2',
        label: 'COPERNICUS'
    });
    const [fields, setFields] = React.useState([]);
    const [collections, setCollections] = React.useState([]);
    const [years, setYears] = React.useState([2023, 2022, 2021, 2020, 2019, 2018]);
    const [image, setImage] = React.useState(null);
    const [images, setImages] = React.useState(null);
    const [period, setPeriod] = React.useState(null);
    const [date_begion, setDateBegin] = React.useState(null);
    const [date_end, setDateEnd] = React.useState(null);
    const [cloudWithOut, setCloudWithOut] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [access, setAccess] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    React.useEffect(() => {
        if (props.token) {
            // Получить поля
            axios.get('/field/list', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let lds = responce.data.map(f => {
                        f.label = f.name;
                        return f;
                    });
                    setFields(lds);
                }
            });
            // Получить доступные коллекции
            axios.get('/collection/list?limit=50', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let collect_ids = responce.data.items.map(c => {
                        c.label = c.collection_name.split('/')[0];
                        return c;
                    });
                    setCollections(collect_ids);
                }
            });
        }
    }, []);
    React.useEffect(() => {
        let access = images !== null
            && field !== null && collection !== null && year !== null
            && date_begion !== null && date_end !== null;
        if (date_begion && date_end) {
            access = date_begion < date_end;
        }
        setAccess(access);
    }, [field,
        collection,
        year,
        images,
        date_begion,
        date_end]);
    React.useEffect(() => {
        if (field !== null && collection !== null && year !== null) {
            if (props.token) {
                setImages(null);
                setPeriod(null);
                axios.get(`/ndvi/ee/${field.id}/${collection.id}/${year}/images`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${props.token}`
                    }
                })
                    .then((responce) => {
                    if (responce.data) {
                        setImages(responce.data);
                    }
                });
            }
        }
    }, [field, collection, year]);
    React.useEffect(() => {
        let dates = images === null || images === void 0 ? void 0 : images.features.filter(x => x.bands.filter(y => y.id == 'B4' || y.id == 'B8') && x.type == 'Image').map(f => {
            return new Date(f.properties['system:time_start']);
        });
        if (dates) {
            const maxDate = new Date(Math.max.apply(null, dates));
            const minDate = new Date(Math.min.apply(null, dates));
            setPeriod({
                min: minDate,
                max: maxDate
            });
            setDateBegin(dayjs(minDate));
            setDateEnd(dayjs(maxDate));
        }
    }, [images]);
    const create = () => __awaiter(this, void 0, void 0, function* () {
        if (images !== null && field !== null
            && date_begion !== null && date_end !== null) {
            setProgress(true);
            axios.post("/ndvi/ee/create", JSON.stringify({
                ndvi_field_id: field === null || field === void 0 ? void 0 : field.id,
                ndvi_date_begin: date_begion.format('YYYY-MM-DD'),
                ndvi_date_end: date_end.format('YYYY-MM-DD'),
                ndvi_collection: collection === null || collection === void 0 ? void 0 : collection.id,
                ndvi_with_out_cloud: cloudWithOut
            }), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((response) => {
                if (response.data.error !== null) {
                    setError(response.data.error);
                    destroy();
                    let error = response.data.error;
                    enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                }
                else {
                    destroy();
                    if (props.update != undefined) {
                        props.update();
                    }
                    props.setState(false);
                    enqueueSnackbar('Запись успешно добавлена', { variant: 'success', autoHideDuration: 1000 });
                }
            })
                .catch(function (reason) {
                let error = reason.response.data.detail || reason.message;
                setError(error);
                enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                destroy();
            });
        }
    });
    const destroy = () => __awaiter(this, void 0, void 0, function* () {
        setError(null);
        setField(null);
        setImage(null);
        setImages(null);
        setProgress(false);
    });
    return (React.createElement("div", null,
        React.createElement(Dialog, { maxWidth: 'md', open: props.open, BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            } },
            React.createElement(DialogTitle, null, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u044D\u043B\u0435\u043C\u0435\u043D\u0442"),
            React.createElement(DialogContent, { sx: { width: '570px' } },
                React.createElement(Box, { sx: { padding: '16px' } },
                    React.createElement(Typography, { color: "text.secondary", variant: "body2" }, "NDVI (Normalized Difference Vegetation Index) \u2014 \u043D\u043E\u0440\u043C\u0430\u043B\u0438\u0437\u043E\u0432\u0430\u043D\u043D\u044B\u0439 \u043E\u0442\u043D\u043E\u0441\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0439 \u0438\u043D\u0434\u0435\u043A\u0441 \u0440\u0430\u0441\u0442\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u2014 \u043F\u0440\u043E\u0441\u0442\u043E\u0439 \u043F\u043E\u043A\u0430\u0437\u0430\u0442\u0435\u043B\u044C \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u0430 \u0444\u043E\u0442\u043E\u0441\u0438\u043D\u0442\u0435\u0442\u0438\u0447\u0435\u0441\u043A\u0438 \u0430\u043A\u0442\u0438\u0432\u043D\u043E\u0439 \u0431\u0438\u043E\u043C\u0430\u0441\u0441\u044B (\u043E\u0431\u044B\u0447\u043D\u043E \u043D\u0430\u0437\u044B\u0432\u0430\u0435\u043C\u044B\u0439 \u0432\u0435\u0433\u0435\u0442\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u043C \u0438\u043D\u0434\u0435\u043A\u0441\u043E\u043C).")),
                React.createElement(Divider, { variant: "middle" }),
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(Stack, { spacing: 2, direction: 'row' },
                        React.createElement(Autocomplete, { value: year, onChange: (event, newValue) => {
                                setYear(newValue);
                            }, disabled: progress, options: years, sx: { width: '50%' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u0413\u043E\u0434" })) }),
                        React.createElement(Autocomplete, { value: collection, onChange: (event, newValue) => {
                                setCollection(newValue);
                            }, disabled: progress, options: collections, sx: { width: '50%' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u041A\u043E\u043B\u043B\u0435\u043A\u0446\u0438\u044F" })) })),
                    React.createElement(Autocomplete, { value: field, onChange: (event, newValue) => {
                            setField(newValue);
                        }, disabled: progress, options: fields, sx: { width: 'auto' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u041F\u043E\u043B\u0435" })) }),
                    React.createElement(Stack, { spacing: 2, direction: 'row' },
                        React.createElement(DateFiled, { value: date_begion, setValue: setDateBegin, label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430", min: period === null || period === void 0 ? void 0 : period.min, max: period === null || period === void 0 ? void 0 : period.max, disabled: period == null || progress }),
                        React.createElement(DateFiled, { value: date_end, setValue: setDateEnd, min: period === null || period === void 0 ? void 0 : period.min, max: period === null || period === void 0 ? void 0 : period.max, label: "\u0414\u0430\u0442\u0430 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F", disabled: period == null || progress })),
                    React.createElement(Box, { component: "span", sx: { p: 2, border: '1px dashed grey', display: 'flex', justifyContent: 'center', minHeight: '100px', alignItems: 'center' } },
                        React.createElement(Stack, { spacing: 2, sx: { maxHeight: '400px', overflowX: 'hidden' } },
                            images !== null && (React.createElement(React.Fragment, null,
                                React.createElement(Typography, { color: "text.secondary", variant: "body2", sx: { textAlign: 'center' } }, `Найдено ${images === null || images === void 0 ? void 0 : images.features.length} изображений в коллекции ${collection === null || collection === void 0 ? void 0 : collection.label}`),
                                React.createElement(List, null, images === null || images === void 0 ? void 0 : images.features.filter(x => x.bands.filter(y => y.id == 'B4' || y.id == 'B8') && x.type == 'Image').slice(0, 3).map((f) => {
                                    return (React.createElement(ListItem, { disablePadding: true },
                                        React.createElement(ListItem, null,
                                            React.createElement(ListItemIcon, null,
                                                React.createElement(ImageIcon, null)),
                                            React.createElement(ListItemText, { primary: dayjs(new Date(f.properties['system:time_start'])).format('llll'), secondary: f.id }))));
                                })))),
                            (images === null && field !== null && collection !== null && year !== null) ? (React.createElement("div", { style: { height: '54px' } },
                                React.createElement(CircularProgress, null)))
                                :
                                    (React.createElement(React.Fragment, null)),
                            field == null && collection == null && year == null && (React.createElement(Typography, { color: "text.secondary", variant: "body2", sx: { textAlign: 'center' } }, "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u0442\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0440\u0442\u044B \u0434\u043B\u044F \u043F\u043E\u043B\u0443\u0447\u0430\u0435\u043D\u0438\u044F \u043A\u043E\u043B\u043B\u0435\u043A\u0446\u0438\u0438 \u0441\u043D\u0438\u043C\u043A\u043E\u0432")))),
                    access == true && progress == true && (React.createElement(LinearProgress, null)))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: create, disabled: !access || progress == true }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C"),
                React.createElement(Button, { onClick: () => {
                        destroy();
                        props.setState(false);
                    }, disabled: progress == true }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
