import React, { forwardRef, useImperativeHandle } from 'react';
import { Avatar, Divider, LinearProgress, List, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, ThemeProvider, Typography } from '@mui/material';
import axios from 'axios';
import * as dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import { greenTheme } from './../../themes/NdviTheme';
import { styled } from '@mui/material/styles';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 18,
    height: 18,
    border: `2px solid ${theme.palette.background.paper}`,
}));
const ListNdvi = forwardRef((props, ref) => {
    const [list, setList] = React.useState([]);
    const [current, setCurrent] = React.useState(null);
    const [listGroup, setListGroup] = React.useState([]);
    const [load, setLoad] = React.useState(false);
    useImperativeHandle(ref, () => ({
        update: update
    }));
    React.useEffect(() => {
        update();
    }, []);
    React.useEffect(() => {
        props.select(current);
    }, [current]);
    const update = () => {
        if (props.token) {
            setLoad(true);
            axios.get('/ndvi/list', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let groups = responce.data.map((i) => {
                        return i.ndvi_field.name;
                    });
                    setList(responce.data);
                    setListGroup([...new Set(groups)]);
                    setLoad(false);
                }
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        load == true && (React.createElement(React.Fragment, null,
            React.createElement(LinearProgress, { color: "success" }),
            listGroup.length == 0 && (React.createElement(Typography, { variant: 'caption', component: 'div', align: 'center', style: { color: 'grey', paddingTop: '16px' } }, "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u0441\u043F\u0438\u0441\u043A\u0430 ...")))),
        listGroup.map((g, index) => {
            return (React.createElement(List, { key: `list-group-ndvi-${index}`, sx: { width: '100%', maxWidth: 360, bgcolor: 'background.paper' }, component: "nav", subheader: React.createElement(ListSubheader, { component: "div", id: "nested-list-subheader" }, g.length > 45 ? `${g.substring(0, 42)}...` : g) }, list.filter(f => f.ndvi_field.name == g)
                .map((item) => (React.createElement("div", { key: `cont_${item.id}` },
                React.createElement(ListItemButton, { key: `l_${item.id}`, selected: (current === null || current === void 0 ? void 0 : current.id) == item.id, onClick: () => setCurrent(item) },
                    React.createElement(ListItemAvatar, null,
                        React.createElement(ThemeProvider, { theme: greenTheme },
                            React.createElement(Avatar, { src: `/ndvi/image/${item.id}/${item.ndvi_field.id}`, alt: item.ndvi_field.name, sx: { backgroundColor: '#bdbdbd' }, imgProps: {
                                    style: {
                                        width: '28px',
                                        height: '28px'
                                    }
                                } }))),
                    React.createElement(ListItemText, { primary: `С ${dayjs(item.ndvi_date_begin).format('D MMM')} по ${dayjs(item.ndvi_date_end).format('ll')}`, secondary: `${item.ndvi_name}, ${dayjs(item.ndvi_date_created).format('D MMM')}` })),
                React.createElement(Divider, { key: `d_${item.id}`, variant: "fullWidth", component: "li" }))))));
        })));
});
export default ListNdvi;
