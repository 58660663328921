var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, IconButton } from '@mui/material';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
export default function TiffInfoDialog(_a) {
    var props = __rest(_a, []);
    const [open, setOpen] = React.useState(props.open);
    const [tiff, setTiff] = React.useState(null);
    React.useEffect(() => {
        if (props.open == true) {
            setTiff(null);
            axios.get(`/fertilizer/tiff/detail/${props.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    setTiff(responce.data);
                }
            });
        }
    }, [props.open]);
    const export_band = (id, band) => {
        window.location.href = `/fertilizer/tiff/export/${id}/${band}`;
    };
    return (React.createElement("div", null,
        React.createElement(Dialog, { BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            }, open: props.open, maxWidth: 'md', onClose: props.close },
            React.createElement(DialogTitle, null, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E TIF \u0444\u0430\u0439\u043B\u0435"),
            React.createElement(DialogContent, null, props.open == true && tiff && (React.createElement(React.Fragment, null,
                React.createElement(DialogContentText, null),
                React.createElement(Divider, { sx: { marginTop: '16px' } }),
                React.createElement(DialogContent, { sx: { padding: '20px 0px' } },
                    React.createElement(TableContainer, { component: 'div' },
                        React.createElement(Table, { sx: { minWidth: 650 }, size: "small", "aria-label": "a dense table" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, null, "Number"),
                                    React.createElement(TableCell, { align: "left" }, "Band"),
                                    React.createElement(TableCell, { align: "right" }, "No-Data"),
                                    React.createElement(TableCell, { align: "right" }, "Min"),
                                    React.createElement(TableCell, { align: "right" }, "Max"),
                                    React.createElement(TableCell, { align: "center" }))),
                            React.createElement(TableBody, null, tiff === null || tiff === void 0 ? void 0 : tiff.bands.map((band) => (React.createElement(TableRow, { key: band.id, sx: { '&:last-child td, &:last-child th': { border: 0 } } },
                                React.createElement(TableCell, { component: "th", scope: "row" }, band.id),
                                React.createElement(TableCell, { align: "left" }, band.band),
                                React.createElement(TableCell, { align: "right" }, band.nodata),
                                React.createElement(TableCell, { align: "right" }, band.min),
                                React.createElement(TableCell, { align: "right" }, band.max),
                                React.createElement(TableCell, { align: "center" },
                                    React.createElement(IconButton, { disabled: band.min == 0 && band.max == 0, onClick: () => export_band(props.id, band.id) },
                                        React.createElement(DownloadIcon, null))))))))))))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: props.download }, "\u0421\u043A\u0430\u0447\u0430\u0442\u044C"),
                React.createElement(Button, { onClick: () => props.close(false), autoFocus: true }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
