import React, { forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ImportIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DialogYesNo from './DialodYesNo';
import { Tooltip } from '@mui/material';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
const StorageAppBar = forwardRef((props, ref) => {
    const [selected, setSelected] = React.useState([]);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const editMapsModels = ['agrochemical'];
    const autoUploadsModels = ['meteo'];
    React.useEffect(() => {
    }, [, selected]);
    useImperativeHandle(ref, () => ({
        setItems: changeSelectedItems,
        selected: selected,
        destroy: resertSelectedItems
    }));
    const changeSelectedItems = (state, value) => {
        let buf = selected.filter(x => x !== value);
        if (state === true) {
            buf.push(value);
        }
        setSelected(buf);
        return buf;
    };
    const resertSelectedItems = () => {
        setSelected([]);
        return [];
    };
    const deleteSelectedItems = () => {
        props.delete(selected);
        setDeleteDialog(false);
    };
    return (React.createElement(React.Fragment, null,
        props.meta !== null && (React.createElement(React.Fragment, null,
            React.createElement(Box, { sx: {
                    display: 'flex',
                    mt: '0px !important',
                    p: '4px 16px',
                    paddingTop: '6px',
                    alignItems: 'center',
                    width: 'auto',
                    bgcolor: 'background.paper',
                    color: 'text.secondary',
                    '& svg': {
                        m: 1.5,
                    },
                    '& hr': {
                        mx: 0.5,
                    }
                } },
                React.createElement(Tooltip, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: () => props.create(true), sx: { width: '40px', height: '40px' }, disabled: !props.meta.create_variant.includes(1) },
                            React.createElement(AddIcon, null)))),
                React.createElement(Tooltip, { title: "\u0418\u043C\u043F\u043E\u0440\u0442 \u0434\u0430\u043D\u043D\u044B\u0445" },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: () => props.import(true), sx: { width: '40px', height: '40px' }, disabled: !props.meta.create_variant.includes(2) },
                            React.createElement(ImportIcon, null)))),
                React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true }),
                props.meta.create_variant.includes(3) && (React.createElement("span", null,
                    React.createElement(IconButton, { onClick: () => props.update(selected.length > 0 ? selected[0] : 0), sx: { width: '40px', height: '40px' }, disabled: selected.length !== 1 },
                        React.createElement(EditIcon, null)))),
                React.createElement(Tooltip, { title: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: () => setDeleteDialog(true), sx: { width: '40px', height: '40px' }, disabled: selected.length === 0 },
                            React.createElement(DeleteIcon, null)))),
                editMapsModels.includes(props.model) && props.editmap !== undefined && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true }),
                    React.createElement(Tooltip, { title: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0434\u0430\u043D\u043D\u044B\u0435 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435" },
                        React.createElement("span", null,
                            React.createElement(IconButton, { onClick: () => props.editmap ? props.editmap(true) : () => { }, sx: { width: '40px', height: '40px' }, disabled: props.editmap == undefined },
                                React.createElement(EditLocationIcon, null)))))),
                autoUploadsModels.includes(props.model) && props.autoupload !== undefined && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true }),
                    React.createElement(Tooltip, { title: "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0438\u0437 \u0432\u043D\u0435\u0448\u043D\u0435\u0433\u043E \u0438\u0441\u0442\u043E\u0447\u043D\u0438\u043A\u0430" },
                        React.createElement("span", null,
                            React.createElement(IconButton, { onClick: () => props.autoupload ? props.autoupload(true) : () => { }, sx: { width: '40px', height: '40px' }, disabled: props.autoupload == undefined },
                                React.createElement(CloudDownloadIcon, null))))))),
            props.children)),
        React.createElement(DialogYesNo, { open: deleteDialog, no: setDeleteDialog, question: `Данные будут удалены безвозвратно. Вы действительно хотите удалить ${selected.length} элементов?`, title: 'Удаление объектов', yes: deleteSelectedItems })));
});
export default StorageAppBar;
