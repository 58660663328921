import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Home from './controllers/home';
import Login from './controllers/login';
import Nvdi from './controllers/nvdi';
import Statistic from './controllers/statistic';
import Storage from './controllers/storage';
import useToken from './services/token';
import { SSEProvider } from 'react-hooks-sse';
import Flight from './controllers/flight';
import Fertilizer from './controllers/fertilizer';
import Weed from './controllers/weed';
const App = () => {
    const { token, verify, removeToken } = useToken();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (!token && token !== "" && token !== undefined) {
            removeToken();
            navigate('/login');
        }
        else {
            if (verify() === false) {
                removeToken();
                navigate('/login');
            }
        }
    }, []);
    return (!token && token !== "" && token !== undefined
        ?
            React.createElement(Login, null)
        :
            React.createElement(SSEProvider, { endpoint: `/sse/${token}` },
                React.createElement(Routes, null,
                    React.createElement(Route, { path: "/", element: React.createElement(Home, { token: token }) }),
                    React.createElement(Route, { path: "/storage", element: React.createElement(Storage, { token: token }) }),
                    React.createElement(Route, { path: "/ndvi", element: React.createElement(Nvdi, { token: token }) }),
                    React.createElement(Route, { path: "/fertilizer", element: React.createElement(Fertilizer, { token: token }) }),
                    React.createElement(Route, { path: "/statistic", element: React.createElement(Statistic, { token: token }) }),
                    React.createElement(Route, { path: "/flight", element: React.createElement(Flight, { token: token }) }),
                    React.createElement(Route, { path: "/login", element: React.createElement(Navigate, { to: "/" }) }),
                    React.createElement(Route, { path: "/weed", element: React.createElement(Weed, { token: token }) }))));
};
export default App;
