var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { Checkbox, Grid } from '@mui/material';
import StorageAppBar from './StorageAppBar';
import ImportDialog from '../storage/Import';
import CreateDialog from '../storage/Create';
import { useSnackbar } from 'notistack';
import UpdateDialog from '../storage/Update';
import EditInMapDialog from '../storage/EditInMap';
import * as dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import AutoUploadDialog from '../storage/AutoApload';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
export default function StorageTable(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [columns, setColumns] = React.useState([]);
    const [data, setData] = React.useState(null);
    const [countData, setCountData] = React.useState(0);
    const [createDialog, setCreateDialog] = React.useState(false);
    const [updateDialog, setUpdateDialog] = React.useState(false);
    const [importDialog, setImportDialog] = React.useState(false);
    const [editMapDialog, setEditMapDialog] = React.useState(false);
    const [metaAutoUpload, setMetaAutoUpload] = React.useState(null);
    const [autoUploadDialog, setAutoUploadDialog] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const tableBar = React.useRef(null);
    React.useEffect(() => {
        if (props.token) {
            get();
            check_autoupload(props.model);
        }
    }, [, page, rowsPerPage]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const refresh = () => {
        if (props.token) {
            get();
        }
    };
    const check_autoupload = (model) => {
        if (model != '') {
            axios.get(`${model}/autoupload`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            }).
                then((responce) => {
                if (responce.data) {
                    setMetaAutoUpload(responce.data);
                }
            })
                .catch((reason) => {
                setMetaAutoUpload(null);
            });
        }
        return false;
    };
    const get = () => {
        let col = props.meta.columns
            .filter(x => x.name !== 'id')
            .map((item) => {
            let _width = 100;
            if (item.type_ == 'datetime') {
                _width = 150;
            }
            if (item.type_ == 'integer' || item.type_ == 'double') {
                _width = 60;
            }
            let label = item.description.replace(`${props.prefix}_`, '').toUpperCase();
            let relation = null;
            let id = item.name;
            if (item.relation.length > 0) {
                relation = item.relation[0]._view;
                label = label.replace('_ID', '');
                id = item.relation[0]._fk.replace('_id', '');
            }
            return {
                id: id,
                label: label,
                minWidth: _width,
                relation: relation
            };
        });
        setColumns(col);
        axios.get(`${props.model}/list?limit=${rowsPerPage}&offset=${page * rowsPerPage}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        }).
            then((responce) => {
            if (responce.data) {
                setData(responce.data.items);
                setCountData(responce.data.total);
            }
        });
    };
    const toggleCheckBox = (select, value) => {
        if (tableBar.current) {
            setSelected(tableBar.current.setItems(select, value));
        }
    };
    const deleteRows = (values) => {
        let data = {
            items: selected
        };
        axios.delete(`${props.model}/delete`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            },
            data: JSON.stringify(data)
        })
            .then((responce) => {
            if (responce.data) {
                if (tableBar.current) {
                    setSelected(tableBar.current.destroy());
                }
                get();
                enqueueSnackbar('Записи удалены', { variant: 'success', autoHideDuration: 1000 });
            }
        })
            .catch((reason) => {
            if (tableBar.current) {
                setSelected(tableBar.current.destroy());
            }
            let error = reason.response.data.detail || reason.message;
            enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
        });
    };
    const updateRow = (value) => {
        if (value) {
            setUpdateDialog(true);
        }
    };
    const editMapData = () => {
    };
    const resertSelected = () => {
        if (tableBar.current) {
            setSelected(tableBar.current.destroy());
        }
    };
    return (React.createElement(Paper, { sx: { width: '100%', overflow: 'hidden', margin: '0px !important', boxShadow: 'none' } },
        React.createElement(Grid, { container: true, sx: { borderBottom: (theme) => `1px solid ${theme.palette.divider}` } },
            React.createElement(Grid, { item: true, xs: true },
                React.createElement(StorageAppBar, { ref: tableBar, model: props.model, meta: props.meta, import: setImportDialog, create: setCreateDialog, update: updateRow, delete: deleteRows, editmap: setEditMapDialog, autoupload: metaAutoUpload ? setAutoUploadDialog : undefined },
                    React.createElement(React.Fragment, null,
                        React.createElement(ImportDialog, { token: props.token, model: props.model, open: importDialog, title: props.title, meta: props.meta, setState: setImportDialog, refresh: refresh }),
                        React.createElement(CreateDialog, { token: props.token, model: props.model, open: createDialog, title: props.title, meta: props.meta, prefix: props.prefix, setState: setCreateDialog, refresh: refresh }),
                        selected.length > 0
                            && data
                            && props.meta.create_variant.includes(3)
                            && (React.createElement(UpdateDialog, { item: (data === null || data === void 0 ? void 0 : data.filter(x => x.id == selected[0]).length) > 0 ? data === null || data === void 0 ? void 0 : data.filter(x => x.id == selected[0])[0] : 0, token: props.token, model: props.model, open: updateDialog, title: props.title, meta: props.meta, prefix: props.prefix, setState: setUpdateDialog, refresh: refresh })),
                        editMapDialog == true && (React.createElement(EditInMapDialog, { open: editMapDialog, setState: setEditMapDialog, reference: props.model, token: props.token })),
                        metaAutoUpload != null && (React.createElement(AutoUploadDialog, { token: props.token, model: props.model, open: autoUploadDialog, title: props.title, columns: metaAutoUpload, prefix: props.prefix, setState: setAutoUploadDialog, refresh: refresh }))))),
            React.createElement(Grid, { item: true, xs: "auto" },
                React.createElement(TablePagination, { labelRowsPerPage: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E: ', labelDisplayedRows: (info) => {
                        return `${info.from}–${info.to} из ${info.count !== -1 ? info.count : `больше чем ${info.to}`}`;
                    }, rowsPerPageOptions: [25, 50, 100], component: "div", count: countData, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }))),
        React.createElement(TableContainer, { sx: {
                height: 'calc(100vh - 228px)',
                width: 'calc(100vw - 65px)',
                "&::-webkit-scrollbar": {
                    width: 8,
                    height: 8
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "#F1F1F1"
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#C1C1C1",
                    borderRadius: 2
                }
            } },
            React.createElement(Table, { stickyHeader: true, "aria-label": "sticky table", size: "small" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { padding: "checkbox", style: { backgroundColor: 'white' } },
                            React.createElement(Checkbox, { color: "primary", disabled: selected.length === 0, checked: selected.length !== 0, onChange: () => resertSelected(), inputProps: {
                                    'aria-label': 'select all desserts',
                                } })),
                        columns.map((column, index) => {
                            return (React.createElement(TableCell, { key: `t_${index}`, align: column.align, style: { minWidth: column.minWidth, backgroundColor: 'white', whiteSpace: 'nowrap' } }, column.label));
                        }))),
                React.createElement(TableBody, null, data !== null && (React.createElement(React.Fragment, null, data.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const id = Number(row['id']);
                    return (React.createElement(TableRow, { key: `tr_${index}`, selected: selected.includes(id), hover: true, role: "checkbox", tabIndex: -1 },
                        React.createElement(TableCell, { padding: "checkbox" },
                            React.createElement(Checkbox, { checked: selected.includes(id), onChange: (e) => toggleCheckBox(e.target.checked, id), color: "primary", inputProps: {
                                    'aria-labelledby': labelId,
                                } })),
                        columns.map((column) => {
                            let value = '';
                            if (column.relation) {
                                try {
                                    value = row[column.id][column.relation];
                                }
                                catch (_a) { }
                            }
                            else {
                                value = row[column.id];
                                if (!isNaN(value)) {
                                    value = value % 1 !== 0 ? Number(value).toFixed(2) : value;
                                }
                                if (typeof (value) == typeof (true)) {
                                    value = value == true ? 'Да' : 'Нет';
                                }
                                if (column.id == 'password') {
                                    value = '*******';
                                }
                                if (column.id.indexOf('_datetime') > 0 && typeof (value) == typeof ('')) {
                                    value = dayjs(value).format('lll');
                                }
                            }
                            return (React.createElement(TableCell, { key: column.id, align: column.align, style: { whiteSpace: column.relation ? 'nowrap' : 'normal' } }, value));
                        })));
                }))))))));
}
