var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack } from '@mui/system';
import { Divider } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import OpenStreetMaps from '../map/OpenStreetMaps';
import axios from 'axios';
export default function EditInMapDialog(_a) {
    var props = __rest(_a, []);
    const map = React.useRef(null);
    const [field, setField] = React.useState(null);
    const [date, setDate] = React.useState(null);
    const [fields, setFields] = React.useState([]);
    const [dates, setDates] = React.useState([]);
    const [selectedElement, setSelectedElement] = React.useState(0);
    const [center, setCenter] = React.useState({ lat: 49.9714, lng: 82.6059 });
    React.useEffect(() => {
        if (props.reference) {
            get_fields();
            get_dates();
        }
    }, [props.reference]);
    React.useEffect(() => {
        var _a;
        if (props.open == true
            && field != null
            && date != null) {
            (_a = map.current) === null || _a === void 0 ? void 0 : _a.setEditebleObject(`${props.reference}/edit/map/${field === null || field === void 0 ? void 0 : field.id}/${date === null || date === void 0 ? void 0 : date.id}`);
        }
    }, [field, date]);
    const get_fields = () => {
        setFields([
            {
                id: 1,
                label: "Опытное поле",
                name: '',
                date_created: new Date(),
                file_path: '',
                latitude_center: 0,
                longitude_center: 0,
                user_id: 1,
                plot_count: 0
            }
        ]);
    };
    const get_dates = () => {
        setDates([
            {
                id: '2022-09-06',
                label: '2022-09-06'
            }
        ]);
    };
    const get_data = () => {
        axios.get(`/${props.reference}/edit/map/data`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        })
            .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
        });
    };
    const save = (data) => {
        axios.put(`${props.reference}/edit/map/${field === null || field === void 0 ? void 0 : field.id}/${date === null || date === void 0 ? void 0 : date.id}`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        })
            .then((responce) => {
            if (responce.data) {
                console.log(responce.data);
            }
        })
            .catch((reason) => {
        });
    };
    const close = () => {
        props.setState(false);
    };
    return (React.createElement("div", null,
        React.createElement(Dialog, { maxWidth: 'md', open: props.open, BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            } },
            React.createElement(DialogTitle, null, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0434\u0430\u043D\u043D\u044B\u0435 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435"),
            React.createElement(DialogContent, null,
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(Stack, { spacing: 2, direction: 'row', divider: React.createElement(Divider, { orientation: "vertical", flexItem: true }) },
                        React.createElement(Autocomplete, { value: field, onChange: (e, newValue) => {
                                setField(newValue);
                            }, disablePortal: true, id: "combo-box-demo", options: fields, sx: { width: '50%' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u0423\u0447\u0430\u0441\u0442\u043E\u043A" })) }),
                        React.createElement(Autocomplete, { value: date, onChange: (e, newValue) => {
                                setDate(newValue);
                            }, disablePortal: true, id: "combo-box-demo", options: dates, sx: { width: '50%' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u0414\u0430\u0442\u0430 \u0437\u0430\u043C\u0435\u0440\u043E\u0432" })) })),
                    React.createElement(Box, { component: "span", sx: { p: 0, border: '1px dashed grey', display: 'flex', width: '700px', height: '480px' } },
                        React.createElement(OpenStreetMaps, { ref: map, token: props.token, url: null, center: center, editMode: {
                                polygon: false,
                                circlemarker: false,
                                remove: false
                            }, setEditResult: save, draw: false, item: 0 })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => close() }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C")))));
}
