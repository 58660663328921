var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Box } from '@mui/material';
import Chart from './Chart';
import Grid from '@mui/material/Unstable_Grid2';
function ChartContainer(_a) {
    var props = __rest(_a, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: {
                flexGrow: 1,
                paddingLeft: '16px',
                height: 'calc(100vh - 179px)',
                overflow: 'auto',
                '& > :not(style)': {
                    m: 1
                },
                "&::-webkit-scrollbar": {
                    width: 8
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "#F1F1F1"
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#C1C1C1",
                    borderRadius: 2
                }
            } },
            React.createElement(Grid, { sx: {
                    paddingTop: '16px'
                }, container: true, spacing: 6 }, props.chart.pages.map((page, index) => {
                return (React.createElement(Grid, { key: index, xs: 'auto', minHeight: 450 },
                    React.createElement(Chart, { token: props.token, pages: page, title: '' })));
            })))));
}
export default ChartContainer;
