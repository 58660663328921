import L from 'leaflet';
export const getMarkerIcon = (value) => {
    let url = `/static/images/${value}.png`;
    return L.icon({
        iconUrl: url,
        iconSize: [40, 40],
        iconAnchor: [20, 35],
        tooltipAnchor: [20, -20]
    });
};
