var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DataGrid from './storage/DataGrid';
import axios from 'axios';
import TabElement from './elements/TabElement';
function Storages(_a) {
    var props = __rest(_a, []);
    const [value, setValue] = React.useState(0);
    const [tabs, setTabs] = React.useState([]);
    React.useEffect(() => {
        if (props.token) {
            axios.get(`/storage/tabs`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            }).
                then((responce) => {
                if (responce.data) {
                    let data = responce.data;
                    setTabs(data);
                }
            });
        }
    }, []);
    React.useEffect(() => {
    }, [tabs]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 2, sx: { padding: '0px', flex: '1 1 auto', pt: '20px' } },
            React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", sx: { pl: 3 } },
                React.createElement(Link, { underline: "hover", color: "inherit", href: "/" }, "Agriculture"),
                React.createElement(Typography, { color: "text.primary" }, "\u0414\u0430\u043D\u043D\u044B\u0435")),
            React.createElement(Divider, null),
            React.createElement(Box, { sx: { marginTop: '0px !important', bgcolor: 'background.paper' } },
                React.createElement(Tabs, { value: value, onChange: handleChange, variant: "scrollable", scrollButtons: true, sx: {
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                        borderBottom: '1px solid #eee',
                        maxWidth: 'calc(100vw - 65px)'
                    } }, tabs.map((t, index) => {
                    return (React.createElement(Tab, { key: `tab_${index}`, label: t.title, disabled: !t.active, sx: {
                            borderLeft: (t.model == 'user' ? 'dashed 1px lightgrey' : 'none')
                        } }));
                }))),
            tabs.map((t, index) => {
                return (React.createElement(TabElement, { key: `tab_panel_${index}`, value: value, index: index },
                    React.createElement(DataGrid, { token: props.token, title: t.title, model: t.model, prefix: t.prefix })));
            }))));
}
export default Storages;
