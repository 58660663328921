import React, { forwardRef } from 'react';
import { Avatar, Divider, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import * as dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
const ProcessList = forwardRef((props, ref) => {
    return (React.createElement(React.Fragment, null, props.items.length > 0
        ?
            (React.createElement(List, { sx: { width: '100%', maxWidth: 360, bgcolor: 'background.paper' }, component: "nav", subheader: React.createElement(ListSubheader, { component: "div", id: "nested-list-subheader" }, `В обработке...`) }, props.items.map((item) => (React.createElement("div", { key: `process_${item.field}` },
                React.createElement(ListItem, { key: `process_l_${item.field}`, disablePadding: true },
                    React.createElement(ListItemButton, { disabled: true },
                        React.createElement(ListItemAvatar, null,
                            React.createElement(Avatar, { src: `/field/image/${item.field}`, sx: { backgroundColor: '#bdbdbd' }, imgProps: {
                                    style: {
                                        width: '28px',
                                        height: '28px'
                                    }
                                } })),
                        React.createElement(ListItemText, { primary: `С ${dayjs(item.begin).format('D MMM')} по ${dayjs(item.end).format('ll')}`, secondary: React.createElement(LinearProgress, { variant: 'indeterminate', sx: { margin: '8px 0px' } }) }))),
                React.createElement(Divider, { key: `process_d_${item.field}`, variant: "fullWidth", component: "li" }))))))
        :
            (React.createElement(React.Fragment, null))));
});
export default ProcessList;
