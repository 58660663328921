var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack } from '@mui/system';
import { Divider, FormControlLabel, Switch, Typography } from '@mui/material';
import axios from 'axios';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useSnackbar } from 'notistack';
import Password from '../admin/Password';
export default function UpdateDialog(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = React.useState(null);
    const [access, setAccess] = React.useState(false);
    const [relationData, setRelationData] = React.useState([]);
    const data = React.useRef([]);
    React.useEffect(() => {
        if (props.meta.create_variant.includes(1)) {
            let urls = props.meta.columns.filter(x => x.relation.length > 0)
                .map((rel) => {
                let promise = {
                    url: `/${rel.relation[0]._to}/list?limit=100`,
                    get: axios.get(`/${rel.relation[0]._to}/list?limit=100`, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${props.token}`
                        }
                    }),
                    view: rel.relation[0]._view
                };
                return promise;
            });
            if (urls.length > 0) {
                axios.all(urls.map(x => x.get))
                    .then(axios.spread((...res) => {
                    let items = res.map((config) => {
                        let view = urls.filter(x => x.url === config.config.url);
                        let data_items = 'items' in config.data ? config.data.items : config.data;
                        let r = {
                            url: config.config.url,
                            data: data_items.map((item) => {
                                let value = {
                                    value: item.id,
                                    name: view[0].view in item ? item[view[0].view] : ''
                                };
                                return value;
                            })
                        };
                        return r;
                    });
                    setRelationData(items);
                }));
            }
        }
    }, [props.meta.columns]);
    React.useEffect(() => {
        let defaultValues = props.meta.columns.filter(x => x.name !== 'id').map((x) => {
            return {
                name: x.name,
                value: props.item[x.name]
            };
        });
        data.current = defaultValues;
        check();
    }, [props.item]);
    const change = (name, e, checked = null) => {
        let fv = {
            name: name,
            value: e == null ? '' : (checked || e.target.value)
        };
        let fields = data.current.filter(x => x.name !== name);
        fields.push(fv);
        data.current = fields;
        check();
    };
    const check = () => {
        let names = props.meta.columns.filter(x => x.name !== 'id').map((x) => {
            return x.name;
        });
        let mod = data.current.filter(x => names.includes(x.name) == true && x.value !== '');
        setAccess(mod.length == names.length);
    };
    const update = () => {
        let data_value = {
            id: "0"
        };
        data.current.forEach((e) => {
            data_value[e.name] = e.value;
        });
        axios.put(`${props.model}/update/${props.item.id}`, JSON.stringify(data_value), {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        })
            .then((responce) => {
            if (responce.data) {
                if (props.refresh) {
                    props.refresh();
                }
                props.setState(false);
                enqueueSnackbar('Запись успешно обновленая', { variant: 'success' });
            }
        })
            .catch((reason) => {
            let deatailisArray = Array.isArray(reason.response.data.detail);
            let error = (deatailisArray == false ? reason.response.data.detail : reason.response.data.detail[0].msg) || reason.message;
            enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
        });
    };
    const getType = (type) => {
        if (type == 'integer' || type == 'double') {
            return 'number';
        }
        else if (type == 'datetime') {
            return 'datetime-local';
        }
        else if (type == 'date') {
            return 'date';
        }
        else if (type == 'boolean') {
            return 'boolean';
        }
        else {
            return 'text';
        }
    };
    return (React.createElement("div", null,
        React.createElement(Dialog, { maxWidth: 'xs', open: props.open, BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            } },
            React.createElement(DialogTitle, null, `Обновить ${props.title}`),
            React.createElement(DialogContent, null,
                React.createElement(Box, { sx: { padding: '16px' } },
                    React.createElement(Typography, { color: "text.secondary", variant: "body2" }, "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0437\u0430\u043F\u0438\u0441\u044C \u0432 \u0442\u0430\u0431\u043B\u0438\u0446\u0435")),
                React.createElement(Divider, { variant: "middle" }),
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(Stack, { component: "form", sx: {
                            width: 'auto',
                            minWidth: '340px'
                        }, spacing: 2, noValidate: true, autoComplete: "off" }, props.meta.columns.filter(x => x.name !== 'id')
                        .map((column, index) => {
                        var _a;
                        let _type = getType(column.type_);
                        if (column.name == 'password') {
                            _type = 'password';
                        }
                        return (column.relation.length == 0
                            ?
                                (React.createElement(React.Fragment, null, _type !== 'boolean'
                                    ?
                                        (React.createElement(React.Fragment, null, _type == 'password'
                                            ?
                                                (React.createElement(Password, { disabled: props.token == null, setPassword: (value) => change(column.name, value) }))
                                            :
                                                (React.createElement(TextField, { autoComplete: 'new-password', disabled: props.token == null || column.name == 'username', onChange: (e) => change(column.name, e), type: _type, key: `field_${column.name}`, label: column.type_.includes('date') ? '' : column.description.replace(`${props.prefix}_`, ''), helperText: column.type_.includes('date') == false ? '' : column.description.replace(`${props.prefix}_`, ''), id: column.name, defaultValue: column.name != 'password' ? props.item[column.name] : '', variant: "filled" }))))
                                    :
                                        (React.createElement(FormControlLabel, { disabled: props.token == null, key: `field_${column.name}`, control: React.createElement(Switch, { id: column.name, onChange: (e, c) => change(column.name, e, c), defaultChecked: props.item[column.name], name: column.name }), label: column.type_.includes('date') ? '' : column.description.replace(`${props.prefix}_`, '') }))))
                            :
                                (React.createElement(FormControl, { key: `field_for__${column.name}`, fullWidth: true, variant: "filled" },
                                    React.createElement(Select, { defaultValue: (_a = props.item[column.relation[0]._fk.replace('_id', '')]) === null || _a === void 0 ? void 0 : _a.id, id: column.name, key: `field_${column.name}`, onChange: (e) => change(column.name, e), disabled: props.token == null }, relationData.filter(x => x.url === `/${column.relation[0]._to}/list?limit=100`)
                                        .map((item) => {
                                        return (item.data.map((kv) => {
                                            return (React.createElement(MenuItem, { value: kv.value }, kv.name));
                                        }));
                                    })),
                                    React.createElement(FormHelperText, null, column.description.replace(`${props.prefix}_`, '')))));
                    })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => update(), disabled: !access }, "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C"),
                React.createElement(Button, { onClick: () => props.setState(false) }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
