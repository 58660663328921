var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DateFiled from '../elements/DateFiled';
import { Box, Stack } from '@mui/system';
import { Divider, FormControl, InputLabel, LinearProgress, MenuItem, Select, Typography } from '@mui/material';
import { useUploadForm } from '../../utils/upload';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useSnackbar } from 'notistack';
export default function AddDialog(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const { uploadForm, destroyControls, progress, isSuccess } = useUploadForm("/ndvi/create");
    const [name, setName] = React.useState('');
    const [field, setField] = React.useState(null);
    const [fields, setFields] = React.useState([]);
    const [bands, setBands] = React.useState([]);
    const [date_begion, setDateBegin] = React.useState(null);
    const [date_end, setDateEnd] = React.useState(null);
    const [fileTiff, setFileTiff] = React.useState(null);
    const [red, setRed] = React.useState(4);
    const [nir, setNir] = React.useState(8);
    const [error, setError] = React.useState(null);
    const [access, setAccess] = React.useState(false);
    const [as_task, setAsTask] = React.useState(false);
    const fileChange = (event) => {
        setFileTiff(event.target.files ? event.target.files[0] : null);
        if (event.target.files) {
            const size = event.target.files[0].size / (1024 * 1024);
            setAsTask(size > 1);
        }
    };
    React.useEffect(() => {
        if (props.token) {
            axios.get('/field/list', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let lds = responce.data.map(f => {
                        f.label = f.name;
                        return f;
                    });
                    setFields(lds);
                }
            });
        }
    }, []);
    React.useEffect(() => {
        if (fileTiff && as_task == false) {
            getBands(fileTiff);
        }
        else {
            let _bands = [];
            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((x) => {
                _bands.push({
                    index: x,
                    name: `Канал ${x}`
                });
            });
            setBands(_bands);
        }
    }, [fileTiff]);
    React.useEffect(() => {
        setAccess(date_begion !== null && date_end !== null
            && fileTiff !== null
            && name !== '' && field !== null);
    }, [name, date_begion, date_end, fileTiff]);
    const create = () => __awaiter(this, void 0, void 0, function* () {
        if (date_begion !== null && date_end !== null
            && fileTiff !== null
            && name !== '' && field !== null) {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("field", field === null || field === void 0 ? void 0 : field.id.toString());
            formData.append("date_begin", date_begion.format('YYYY-MM-DD'));
            formData.append("date_end", date_end.format('YYYY-MM-DD'));
            formData.append("tif", fileTiff);
            formData.append("red", red.toString());
            formData.append("nir", nir.toString());
            formData.append("task", as_task.toString());
            yield uploadForm(props.token, formData)
                .then(function (response) {
                if (response.data.error !== null) {
                    setError(response.data.error);
                    destroy();
                    let error = response.data.error;
                    enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                }
                else {
                    destroy();
                    if (props.update) {
                        props.update();
                    }
                    props.setState(false);
                    enqueueSnackbar('Запись успешно добавлена', { variant: 'success', autoHideDuration: 1000 });
                }
            })
                .catch(function (reason) {
                let error = reason.response.data.detail || reason.message;
                setError(error);
                enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                destroy();
            });
        }
    });
    const getBands = (_file) => __awaiter(this, void 0, void 0, function* () {
        if (_file !== null) {
            const formData = new FormData();
            formData.append("tif", _file);
            yield axios.post('/ndvi/bands', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((response) => {
                setBands(response.data);
            })
                .catch((error) => {
                setError(error);
            });
        }
    });
    const destroy = () => __awaiter(this, void 0, void 0, function* () {
        setName('');
        setDateBegin(null);
        setDateEnd(null);
        setFileTiff(null);
        setError(null);
        setField(null);
        destroyControls();
    });
    return (React.createElement("div", null,
        React.createElement(Dialog, { maxWidth: 'xs', open: props.open, BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            } },
            React.createElement(DialogTitle, null, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u044D\u043B\u0435\u043C\u0435\u043D\u0442"),
            React.createElement(DialogContent, null,
                React.createElement(Box, { sx: { padding: '16px' } },
                    React.createElement(Typography, { color: "text.secondary", variant: "body2" }, "NDVI (Normalized Difference Vegetation Index) \u2014 \u043D\u043E\u0440\u043C\u0430\u043B\u0438\u0437\u043E\u0432\u0430\u043D\u043D\u044B\u0439 \u043E\u0442\u043D\u043E\u0441\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0439 \u0438\u043D\u0434\u0435\u043A\u0441 \u0440\u0430\u0441\u0442\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u2014 \u043F\u0440\u043E\u0441\u0442\u043E\u0439 \u043F\u043E\u043A\u0430\u0437\u0430\u0442\u0435\u043B\u044C \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u0430 \u0444\u043E\u0442\u043E\u0441\u0438\u043D\u0442\u0435\u0442\u0438\u0447\u0435\u0441\u043A\u0438 \u0430\u043A\u0442\u0438\u0432\u043D\u043E\u0439 \u0431\u0438\u043E\u043C\u0430\u0441\u0441\u044B (\u043E\u0431\u044B\u0447\u043D\u043E \u043D\u0430\u0437\u044B\u0432\u0430\u0435\u043C\u044B\u0439 \u0432\u0435\u0433\u0435\u0442\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u043C \u0438\u043D\u0434\u0435\u043A\u0441\u043E\u043C).")),
                React.createElement(Divider, { variant: "middle" }),
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(Autocomplete, { value: field, onChange: (event, newValue) => {
                            setField(newValue);
                        }, disablePortal: true, id: "combo-box-demo", options: fields, sx: { width: 'auto' }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "\u041F\u043E\u043B\u0435" })) }),
                    React.createElement(TextField, { margin: "dense", id: "name", label: "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435", fullWidth: true, variant: "filled", inputProps: {
                            maxLength: props.maxLength
                        }, onChange: (e) => setName(e.target.value) }),
                    React.createElement(DateFiled, { value: date_begion, setValue: setDateBegin, label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430" }),
                    React.createElement(DateFiled, { value: date_end, setValue: setDateEnd, label: "\u0414\u0430\u0442\u0430 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F" }),
                    React.createElement(Box, { component: "span", sx: { p: 2, border: '1px dashed grey', display: 'flex', justifyContent: 'center' } },
                        React.createElement(Stack, { spacing: 2 },
                            React.createElement(Button, { variant: "text", component: "label" },
                                "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0444\u0430\u0439\u043B",
                                React.createElement("input", { hidden: true, accept: ".tif", multiple: false, type: "file", onChange: fileChange })),
                            React.createElement(Typography, { color: "text.secondary", variant: "body2", sx: { textAlign: 'center' } }, fileTiff !== null ? fileTiff.name : 'Файл GeoTIFF не выбран'))),
                    React.createElement(Stack, { direction: 'row', spacing: 2 },
                        React.createElement(FormControl, { fullWidth: true },
                            React.createElement(InputLabel, null, "RED"),
                            React.createElement(Select, { value: red, label: "RED", disabled: bands.length == 0, onChange: (e) => setRed(e.target.value) }, bands.length == 0
                                ?
                                    (React.createElement(MenuItem, { value: 4 }, "B4"))
                                :
                                    (bands.map((b, index) => {
                                        return (React.createElement(MenuItem, { key: `menu-item-key-${index}`, value: b.index }, b.name));
                                    })))),
                        React.createElement(FormControl, { fullWidth: true },
                            React.createElement(InputLabel, null, "IR"),
                            React.createElement(Select, { value: nir, label: "IR", disabled: bands.length == 0, onChange: (e) => setNir(e.target.value) }, bands.length == 0
                                ?
                                    (React.createElement(MenuItem, { value: 8 }, "B8"))
                                :
                                    (bands.map((b, index) => {
                                        return (React.createElement(MenuItem, { key: `menu-item-key-2-${index}`, value: b.index }, b.name));
                                    }))))),
                    progress > 0 && access == true && (React.createElement(LinearProgress, { variant: "determinate", value: progress })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: create, disabled: !access || progress > 0 }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C"),
                React.createElement(Button, { onClick: () => props.setState(false), disabled: progress > 0 }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
