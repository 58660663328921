import { AppBar, Box, Button, Card, CardActions, CardContent, Divider, Grid, Stack, Tab, Tabs, Typography, ThemeProvider } from '@mui/material';
import axios from 'axios';
import React, { useImperativeHandle, forwardRef } from 'react';
import dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import TabPanel from '../elements/TabPanel';
import FactChart from './chats/Fact';
import { orangeTheme, darkBluenTheme } from './../../themes/NdviTheme';
import TiffInfoDialog from './TiffInfo';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
const FertilizerInfoPanel = forwardRef((props, ref) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const defaultBandForTabs = [
        4, 6, 7
    ];
    const [value, setValue] = React.useState(0);
    const [detail, setDetail] = React.useState(null);
    const [tiffDialog, setTiffDialog] = React.useState(false);
    const [band, setBand] = React.useState(defaultBandForTabs[0]);
    const [changeNorm, setChangeNorm] = React.useState(false);
    const handleChange = (event, newValue) => {
        setBand(defaultBandForTabs[newValue]);
        setValue(newValue);
        props.changeTab(newValue);
    };
    useImperativeHandle(ref, () => ({
        setCurrentTab: setValue,
        setBand: setBand
    }));
    React.useEffect(() => {
        if (props.token) {
            axios.get(`/fertilizer/detail/${props.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                var _a;
                if (responce.data) {
                    let data = responce.data;
                    setDetail(data);
                    setChangeNorm(Number(data.fertilizer_nitrogen_max.toFixed(2)) == Number((_a = data.nitrogen_result) === null || _a === void 0 ? void 0 : _a.nr.toFixed(2)));
                }
            });
        }
    }, [, props.id]);
    const dowloadTiff = () => {
        window.location.href = `/fertilizer/${props.id}/tif`;
    };
    return (React.createElement(Box, { sx: { width: '350px', flex: '1 1 auto', display: 'flex', flexDirection: 'column' } },
        detail !== null && (React.createElement(Card, { sx: { minWidth: 275, border: 'none', boxShadow: 'none', borderRadius: 0, display: 'flex', flexDirection: 'column', flex: '1 1 auto' } },
            React.createElement(CardContent, { sx: { pt: '32px', color: 'white', backgroundColor: 'rgb(18, 113, 71)' } },
                React.createElement(Typography, { variant: 'h5', component: 'div', sx: { fontWeight: 200 } }, detail.fertilizer_field.name),
                React.createElement(Typography, { sx: { fontWeight: 200, mb: 1.5, opacity: 0.6 } }, `${detail.fertilizer_culture.cultures_name} - ${detail.fertilizer_grade.grade_name}`),
                React.createElement(Stack, { direction: 'row', spacing: 1, sx: { alignItems: 'center', position: 'relative' } },
                    React.createElement(Typography, { variant: "h2", gutterBottom: true }, detail.fertilizer_ey.toFixed(1)),
                    React.createElement(Typography, { variant: "h4", gutterBottom: true, sx: { opacity: 0.6 } }, "\u0446/\u0413\u0410"))),
            React.createElement(Box, { sx: { width: '100%', bgcolor: 'background.paper' } },
                React.createElement(AppBar, { position: "relative", sx: { boxShadow: 'none' } },
                    React.createElement(Tabs, { value: value, onChange: handleChange, textColor: "inherit", indicatorColor: "secondary" },
                        React.createElement(Tab, { label: "\u0410\u0437\u043E\u0442" }),
                        React.createElement(Tab, { label: "\u0424\u043E\u0441\u0444\u043E\u0440" }),
                        React.createElement(Tab, { label: "\u041A\u0430\u043B\u0438\u0439" })))),
            React.createElement(TabPanel, { value: value, index: 0 },
                changeNorm == false && (React.createElement("div", null,
                    React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u0412\u0445\u043E\u0434\u043D\u044B\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B"),
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                            React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u0414\u043E\u0437\u0430")),
                        React.createElement(Grid, { item: true, xs: true },
                            React.createElement(Typography, { variant: "h6", color: (Number(detail.fertilizer_nitrogen_max.toFixed(2)) - Number((_a = detail.nitrogen_result) === null || _a === void 0 ? void 0 : _a.nr.toFixed(2))) > 0 ? "error" : "inherit", gutterBottom: true, sx: { fontWeight: 200 } }, `${detail.fertilizer_nitrogen_max.toFixed(2)} кг`))))),
                React.createElement("div", { style: { marginTop: `${changeNorm == true ? '0px' : '16px'}` } },
                    React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442"),
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                            React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041D\u0430 \u0433\u0435\u043A\u0442\u0430\u0440")),
                        React.createElement(Grid, { item: true, xs: true },
                            React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${(_b = detail.nitrogen_result) === null || _b === void 0 ? void 0 : _b.nr.toFixed(2)} кг`))),
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                            React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041F\u043B\u043E\u0449\u0430\u0434\u044C")),
                        React.createElement(Grid, { item: true, xs: true },
                            React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${detail.hkr.toFixed(2)} га`))),
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                            React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041F\u043B\u0430\u043D")),
                        React.createElement(Grid, { item: true, xs: true },
                            React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${(_c = detail.nitrogen_result) === null || _c === void 0 ? void 0 : _c.result.toFixed(2)} кг`)))),
                props.variants,
                React.createElement("div", { style: { marginTop: '16px' } },
                    React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u0413\u0440\u0430\u0444\u0438\u043A"),
                    React.createElement(FactChart, { token: props.token, id: detail.id, band: band, filterMap: props.filterMap }))),
            React.createElement(ThemeProvider, { theme: darkBluenTheme },
                React.createElement(TabPanel, { value: value, index: 1 },
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u0412\u0445\u043E\u0434\u043D\u044B\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B"),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                                React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041D\u0430 \u0433\u0435\u043A\u0442\u0430\u0440")),
                            React.createElement(Grid, { item: true, xs: true },
                                React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${(_d = detail.fertilizer_phosphorus_norm) === null || _d === void 0 ? void 0 : _d.toFixed(2)} кг`))),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                                React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041F\u043B\u043E\u0449\u0430\u0434\u044C")),
                            React.createElement(Grid, { item: true, xs: true },
                                React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${(_e = detail.phosphorus_result) === null || _e === void 0 ? void 0 : _e.hkr.toFixed(2)} га`))),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                                React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041F\u043B\u0430\u043D")),
                            React.createElement(Grid, { item: true, xs: true },
                                React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${(_f = detail.phosphorus_result) === null || _f === void 0 ? void 0 : _f.result.toFixed(2)} кг`)))),
                    props.variants,
                    React.createElement("div", { style: { marginTop: '16px' } },
                        React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u0413\u0440\u0430\u0444\u0438\u043A"),
                        React.createElement(FactChart, { token: props.token, id: detail.id, band: band, filterMap: props.filterMap })))),
            React.createElement(ThemeProvider, { theme: orangeTheme },
                React.createElement(TabPanel, { value: value, index: 2 },
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u0412\u0445\u043E\u0434\u043D\u044B\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B"),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                                React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041D\u0430 \u0433\u0435\u043A\u0442\u0430\u0440")),
                            React.createElement(Grid, { item: true, xs: true },
                                React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${(_g = detail.fertilizer_potassium_norm) === null || _g === void 0 ? void 0 : _g.toFixed(2)} кг`))),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                                React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041F\u043B\u043E\u0449\u0430\u0434\u044C")),
                            React.createElement(Grid, { item: true, xs: true },
                                React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${(_h = detail.potassium_result) === null || _h === void 0 ? void 0 : _h.hkr.toFixed(2)} га`))),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                                React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u041F\u043B\u0430\u043D")),
                            React.createElement(Grid, { item: true, xs: true },
                                React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${(_j = detail.potassium_result) === null || _j === void 0 ? void 0 : _j.result.toFixed(2)} кг`)))),
                    props.variants,
                    React.createElement("div", { style: { marginTop: '16px' } },
                        React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u0413\u0440\u0430\u0444\u0438\u043A"),
                        React.createElement(FactChart, { token: props.token, id: detail.id, band: band, filterMap: props.filterMap })))),
            React.createElement(CardActions, { style: { margin: '0px 16px' } },
                React.createElement(Button, { size: "small", onClick: () => setTiffDialog(true) }, "TIFF"),
                React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true, sx: { marginLeft: '8px' } }),
                React.createElement(Button, { size: "small", color: "error", disabled: false, onClick: () => props.delete(props.id) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")))),
        React.createElement(TiffInfoDialog, { token: props.token, id: props.id, close: setTiffDialog, download: dowloadTiff, open: tiffDialog })));
});
export default FertilizerInfoPanel;
