import { Box, Grid } from '@mui/material';
import React from 'react';
function ListAndView({ left, view }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, spacing: 0, sx: { marginTop: '0px !important', height: '100%' } },
            React.createElement(Grid, { item: true, xs: "auto" },
                React.createElement(Box, { sx: {
                        minWidth: '334px',
                        height: '100%'
                    } }, left)),
            React.createElement(Grid, { item: true, xs: true }, view))));
}
export default ListAndView;
