var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Box, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import ListAndView from './elements/ListAndView';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenStreetMaps from './map/OpenStreetMaps';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import DialogYesNo from './elements/DialodYesNo';
import ListFlight from './fligth/List';
import AddFlightDialog from './fligth/Add';
const Fligths = (_a) => {
    var props = __rest(_a, []);
    const maxNameLength = 43;
    const [fligth, setFligth] = React.useState(null);
    const [addDialog, setAddDialog] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const map = React.useRef(null);
    const list = React.useRef(null);
    React.useEffect(() => {
        if (map.current) {
            if (fligth) {
                let latlng = { lat: 49.9714, lng: 82.6059 };
                axios.get(`/flight/points/${fligth === null || fligth === void 0 ? void 0 : fligth.id}/list`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${props.token}`
                    }
                })
                    .then((response) => {
                    if (response.data) {
                        response.data.items.map((point) => {
                            var _a;
                            latlng = {
                                lat: point.stantions_latitude_center,
                                lng: point.stantions_longitude_center
                            };
                            (_a = map.current) === null || _a === void 0 ? void 0 : _a.setUrl(`/flight/get/${fligth === null || fligth === void 0 ? void 0 : fligth.id}`, latlng);
                        });
                    }
                });
            }
        }
    }, [fligth]);
    const deleteListItem = () => {
        axios.delete(`/flight/delete/${fligth === null || fligth === void 0 ? void 0 : fligth.id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        })
            .then((responce) => {
            var _a, _b;
            if (responce.data) {
                if (responce.data.result) {
                    setFligth(null);
                    setDeleteDialog(false);
                    (_a = map.current) === null || _a === void 0 ? void 0 : _a.invalidateSize();
                    (_b = list.current) === null || _b === void 0 ? void 0 : _b.update();
                }
            }
        });
    };
    const updateListItems = () => {
        var _a;
        (_a = list.current) === null || _a === void 0 ? void 0 : _a.update();
    };
    const deleteItem = (x) => {
        setDeleteDialog(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 2, sx: { padding: '0px', flex: '1 1 auto', pt: '20px' } },
            React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", sx: { pl: 3 } },
                React.createElement(Link, { underline: "hover", color: "inherit", href: "/" }, "Agriculture"),
                React.createElement(Typography, { color: "text.primary" }, "\u041F\u043E\u043B\u0435\u0442\u044B")),
            React.createElement(Divider, null),
            React.createElement(ListAndView, { left: React.createElement(React.Fragment, null,
                    React.createElement("div", { style: {
                            backgroundColor: '#fafafa',
                            height: '52px',
                            borderBottom: '1px solid #eaeaea',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0px 16px'
                        } },
                        React.createElement(Tooltip, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" },
                            React.createElement(IconButton, { color: "success", onClick: () => setAddDialog(true) },
                                React.createElement(NoteAddIcon, null))),
                        React.createElement(Tooltip, { title: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" },
                            React.createElement(IconButton, { disabled: fligth == null, color: "error", onClick: () => deleteItem(fligth === null || fligth === void 0 ? void 0 : fligth.id) },
                                React.createElement(DeleteIcon, null))),
                        React.createElement(Divider, { orientation: 'vertical', flexItem: true, sx: { margin: '0px 8px' } }),
                        React.createElement(Tooltip, { title: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0441\u043F\u0438\u0441\u043E\u043A" },
                            React.createElement(IconButton, { onClick: () => updateListItems() },
                                React.createElement(RefreshIcon, null)))),
                    React.createElement(Box, { sx: {
                            height: 'calc(100vh - 180px)',
                            overflowY: 'scroll',
                            "&::-webkit-scrollbar": {
                                width: 8
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#F1F1F1"
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#C1C1C1",
                                borderRadius: 2
                            }
                        } },
                        React.createElement(ListFlight, { ref: list, token: props.token, select: setFligth }))), view: React.createElement(Grid, { container: true, spacing: 0, sx: { marginTop: '0px !important', height: '100%' } },
                    React.createElement(Grid, { item: true, xs: true },
                        React.createElement(OpenStreetMaps, { ref: map, token: props.token, url: null, tollTip: false, showStantions: `flight/points/${fligth == null ? 0 : fligth.id}`, item: fligth == null ? 0 : fligth.id }))) })),
        React.createElement(AddFlightDialog, { token: props.token, open: addDialog, maxLength: maxNameLength, update: updateListItems, setState: setAddDialog }),
        React.createElement(DialogYesNo, { title: '\u0423\u0434\u0430\u043B\u0435\u043D\u0438\u0435', question: `Вы хотите удалить данные о полете ${fligth === null || fligth === void 0 ? void 0 : fligth.name} без возможности восстановления?`, open: deleteDialog, yes: () => deleteListItem(), no: setDeleteDialog })));
};
export default Fligths;
