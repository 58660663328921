import React from 'react';
import { List, ListItemText, ListItemButton, ListSubheader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
// setOpenDeleteDialog, setDeleteIndex
const ListWeed = ({ uploadedFiles, selectedFile, onSelect }) => {
    // Group the files by CropCollection
    const groupedFiles = uploadedFiles.reduce((acc, file) => {
        const { CropCollection } = file;
        if (!acc[CropCollection]) {
            acc[CropCollection] = [];
        }
        acc[CropCollection].push(file);
        return acc;
    }, {});
    function removeFileExtension(filename) {
        return filename.replace(/\.[^/.]+$/, "");
    }
    return (React.createElement(List, { sx: { width: '100%', maxWidth: 360, bgcolor: 'background.paper' } }, Object.keys(groupedFiles).sort().map((CropCollection) => (React.createElement(React.Fragment, { key: CropCollection },
        React.createElement(ListSubheader, { component: "div", id: "nested-list-subheader" }, CropCollection),
        groupedFiles[CropCollection].sort((a, b) => new Date(b.uploadedDate).getTime() - new Date(a.uploadedDate).getTime()).map(({ fileName, uploadedDate }, index) => (React.createElement(ListItemButton, { key: fileName, selected: fileName === selectedFile, onClick: () => onSelect(fileName) },
            React.createElement(Avatar, { alt: fileName, src: `/weed/avatar/${fileName}`, style: { marginRight: '16px' } }),
            React.createElement(ListItemText, { primary: removeFileExtension(fileName), secondary: uploadedDate })))))))));
};
export default ListWeed;
