var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { JsonViewer } from '@textea/json-viewer';
import axios from 'axios';
export default function DialogJsonViewer(_a) {
    var props = __rest(_a, []);
    const [data, setData] = React.useState({});
    React.useEffect(() => {
        if (props.url && props.token) {
            axios.get(props.url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    setData(responce.data);
                }
            });
        }
    }, [, props.url]);
    return (React.createElement("div", null,
        React.createElement(Dialog, { BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            }, open: props.open, maxWidth: 'sm', onClose: props.close },
            React.createElement(DialogTitle, { id: "alert-dialog-title" }, props.title),
            React.createElement(DialogContent, { sx: { minWidth: '500px', maxHeight: '500px' } },
                React.createElement(JsonViewer, { editable: false, value: data })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => props.close(false), autoFocus: true }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C")))));
}
