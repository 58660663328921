import React, { forwardRef } from "react";
import axios from 'axios';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsAccessibility from "highcharts/modules/accessibility";
import { Grid, Typography } from "@mui/material";
highchartsExporting(Highcharts);
highchartsExportData(Highcharts);
highchartsAccessibility(Highcharts);
const FactChart = forwardRef((props, ref) => {
    const [options, setOptions] = React.useState(null);
    const [list, setList] = React.useState([]);
    const [summ, setSumm] = React.useState(0);
    const [area, setArea] = React.useState(0);
    React.useEffect(() => {
        setOptions(null);
        axios.get(`/fertilizer/detail/chart/${props.id}/${props.band}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        })
            .then((responce) => {
            if (responce.data) {
                let detail = responce.data;
                let data = detail.items;
                setList(data);
                setSumm(detail.summ);
                setArea(detail.area);
                let categories = data.map((x) => {
                    return x.value.toString();
                });
                let values = data.map((x) => {
                    return {
                        y: x.summ,
                        color: x.color
                    };
                });
                let colors = data.map((x) => {
                    return x.color;
                });
                const max = data.map(x => x.summ).reduce((acc, val) => {
                    return acc > val ? acc : val;
                });
                setOptions({
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column',
                        height: 140,
                        style: {
                            fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
                        }
                    },
                    title: {
                        text: undefined
                    },
                    xAxis: {
                        categories: categories
                    },
                    yAxis: {
                        title: {
                            text: undefined
                        }
                    },
                    series: [{
                            name: 'Количество',
                            type: 'column',
                            data: values,
                            maxPointWidth: 25
                        }],
                    exporting: {
                        buttons: {
                            contextButton: {
                                enabled: false
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        formatter: function () {
                            let item = data.filter(x => x.value == Number(this.x));
                            return '<b>' + 'Площадь: ' + item[0].ha + ' га</b><br>' + 'Удобрений: ' + item[0].summ + ' кг';
                        },
                        outside: true
                    },
                    colors: colors,
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: function () {
                                        const chart = this.series.chart;
                                        const colors = chart.options.colors;
                                        const data = this.series.data;
                                        let greys = data.filter(x => x.color == 'grey').length;
                                        let selected = this.color !== 'grey' && greys > 0;
                                        if (selected) {
                                            data.forEach((point, i) => point.color = colors ? colors[i] : 'grey');
                                        }
                                        else {
                                            data.forEach((point, i) => {
                                                if (point !== this) {
                                                    point.color = 'grey';
                                                }
                                                else {
                                                    point.color = colors ? colors[i] : 'grey';
                                                }
                                            });
                                        }
                                        chart.redraw();
                                        greys = data.filter(x => x.color == 'grey').length;
                                        let filterMap = data.filter(x => x.color !== 'grey')
                                            .map(x => (Number(x.category) ^ 0) === Number(x.category)
                                            ? `${x.category.toString()}.0`
                                            : `${x.category.toString()}`);
                                        console.log(filterMap);
                                        props.filterMap(greys == 0 ? [] : filterMap);
                                    }
                                }
                            }
                        }
                    },
                });
            }
        });
    }, [props.id, props.band]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { maxHeight: '140px', marginLeft: '-10px', marginTop: '10px', marginBottom: '10px' } }, options !== null && (React.createElement(HighchartsReact, { highcharts: Highcharts, options: options }))),
        options !== null && summ > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: "auto", sx: { alignItems: 'center', display: 'flex' } },
                    React.createElement(Typography, { variant: 'overline', style: { width: '100px', color: 'grey' }, gutterBottom: true }, "\u0424\u0430\u043A\u0442")),
                React.createElement(Grid, { item: true, xs: true },
                    React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { fontWeight: 200 } }, `${summ.toFixed(2)} кг`)))))));
});
export default FactChart;
