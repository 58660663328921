import { Checkbox, Paper } from '@mui/material';
import React, { useImperativeHandle, forwardRef } from 'react';
import Control from 'react-leaflet-custom-control';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/CircleSharp';
import { ThemeProvider } from '@emotion/react';
const ValueSelectedPanel = forwardRef((props, ref) => {
    const [values, setValues] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    React.useEffect(() => {
        var _a;
        (_a = props.map.current) === null || _a === void 0 ? void 0 : _a.setFilterId([]);
        setSelected([...values.map(x => x[0]), "100.0"]);
    }, [values]);
    const handleChangeFilter = (target, checked) => {
        var _a;
        let filter = [];
        if (checked) {
            filter = [...selected.filter(x => x !== target), target];
        }
        else {
            filter = selected.filter(x => x !== target);
        }
        (_a = props.map.current) === null || _a === void 0 ? void 0 : _a.setFilterId(filter);
        setSelected(filter);
    };
    useImperativeHandle(ref, () => ({
        setValues: setValues
    }));
    return (React.createElement(Control, { prepend: true, position: 'bottomleft' },
        React.createElement(Paper, { sx: { opacity: '0.8' } }, values.filter(x => x[1] !== 100).map((i, index) => {
            return (React.createElement(ThemeProvider, { theme: i[2] },
                React.createElement(Checkbox, { checked: selected.length > 0 ? selected.includes(i[0]) : true, onChange: (e, v) => handleChangeFilter(i[0], v), sx: {
                        padding: '4px 9px',
                        color: i[2].palette.primary.main,
                        '&.Mui-checked': {
                            color: i[2].palette.primary.main,
                        },
                    }, icon: React.createElement(CircleOutlinedIcon, null), checkedIcon: React.createElement(CircleIcon, null) })));
        }))));
});
export default ValueSelectedPanel;
