var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AppBar, Box, Button, Card, CardActions, CardContent, Divider, LinearProgress, Stack, Tab, Tabs, ThemeProvider, Typography } from '@mui/material';
import axios from 'axios';
import React, { useImperativeHandle, forwardRef } from 'react';
import * as dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import { greenTheme, orangeTheme, redTheme, yellowGreenTheme, yellowTheme } from './../../themes/NdviTheme';
import DialogJsonViewer from '../elements/JsonViewer';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
function TabPanel(props) {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (React.createElement("div", Object.assign({ role: "tabpanel", hidden: value !== index, id: `simple-tabpanel-${index}`, "aria-labelledby": `simple-tab-${index}`, style: {
            flex: '1 1 auto',
            overflowY: 'auto',
            height: 'calc(0px)'
        } }, other), value === index && (React.createElement(CardContent, { sx: { pt: '24px', backgroundColor: 'white' } }, children))));
}
const NdviInfoPanel = forwardRef((props, ref) => {
    var _a;
    const [value, setValue] = React.useState(0);
    const [detail, setDetail] = React.useState(null);
    const [maxValue, setMaxValue] = React.useState([]);
    const [jsonDialog, setJsonDialog] = React.useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useImperativeHandle(ref, () => ({}));
    React.useEffect(() => {
        if (props.token) {
            axios.get(`/ndvi/meta/${props.field.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                var _a;
                if (responce.data) {
                    let detail = responce.data;
                    let max = Math.max(...Object.values(detail.percents).map(o => o));
                    let target = Object.entries(detail.percents).filter(x => x[1] === max)[0];
                    setMaxValue([Number(target[0]), max]);
                    setDetail(detail);
                    if (props.filterPanel.current) {
                        (_a = props.filterPanel.current) === null || _a === void 0 ? void 0 : _a.setValues(Object.entries(detail.percents).map((v) => {
                            return [v[0], v[1], get_color(Number(v[0]))];
                        }));
                    }
                }
            });
        }
    }, [, props.field.id]);
    const get_color = (value) => {
        if (value <= 0.1) {
            return redTheme;
        }
        else if (value > 0.1 && value <= 0.2) {
            return orangeTheme;
        }
        else if (value > 0.2 && value < 0.39) {
            return yellowTheme;
        }
        else if (value > 0.39 && value < 0.59) {
            return yellowGreenTheme;
        }
        return greenTheme;
    };
    return (React.createElement(Box, { sx: { width: '350px', flex: '1 1 auto', display: 'flex', flexDirection: 'column' } },
        detail !== null && maxValue.length == 2 && (React.createElement(Card, { sx: { minWidth: 275, border: 'none', boxShadow: 'none', borderRadius: 0, display: 'flex', flexDirection: 'column', flex: '1 1 auto' } },
            React.createElement(CardContent, { sx: { pt: '32px', color: 'white', backgroundColor: `rgb(18, 113, 71)` } },
                React.createElement(Typography, { variant: 'h5', component: 'div', sx: { fontWeight: 200 } }, detail.name),
                React.createElement(Typography, { sx: { fontWeight: 200, mb: 1.5, opacity: 0.6 } }, `c ${dayjs(detail.begin).format('LL')} по ${dayjs(detail.end).format('LL')}`),
                React.createElement(Stack, { direction: 'row', spacing: 1, sx: { alignItems: 'center', position: 'relative' } },
                    React.createElement(Typography, { variant: "h2", gutterBottom: true }, maxValue[1].toFixed(2)),
                    React.createElement(Typography, { variant: "h4", gutterBottom: true, sx: { opacity: 0.6 } }, "%"))),
            React.createElement(Box, { sx: { width: '100%', bgcolor: 'background.paper' } },
                React.createElement(AppBar, { position: "relative", sx: { boxShadow: 'none' }, style: { backgroundColor: `rgb(18, 113, 71)` } },
                    React.createElement(Tabs, { value: value, onChange: handleChange, textColor: "inherit", indicatorColor: "secondary" },
                        React.createElement(Tab, { label: "\u041E\u0431\u0449\u0430\u044F" })))),
            React.createElement(TabPanel, { value: value, index: 0 },
                React.createElement(Stack, { spacing: 2 },
                    props.field.ndvi_auto == true && (React.createElement(Box, null,
                        React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u041A\u043E\u043B\u043B\u0435\u043A\u0446\u0438\u044F"),
                        React.createElement(Typography, { display: "block", gutterBottom: true }, (_a = props.field.ndvi_collection_name) === null || _a === void 0 ? void 0 : _a.split('/')[0]))),
                    React.createElement(Box, null,
                        React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u041F\u043E\u043B\u0435"),
                        React.createElement(Typography, { display: "block", gutterBottom: true }, props.field.ndvi_field.name)),
                    React.createElement(Box, null,
                        React.createElement(Typography, { variant: "caption", display: "block", color: 'primary.main', gutterBottom: true }, "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u0435\u043B\u0438"),
                        React.createElement(Stack, { spacing: 4, sx: { marginTop: '16px' } }, Object.entries(detail.percents).map(t => {
                            return (React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Typography, { variant: 'caption' }, Number(t[0])),
                                React.createElement(Box, { sx: { width: '100%', marginTop: '8px !important' } },
                                    React.createElement(ThemeProvider, { theme: get_color(Number(t[0])) },
                                        React.createElement(LinearProgress, { variant: "determinate", color: "primary", value: t[1] }))),
                                React.createElement(Typography, { variant: 'caption', sx: { whiteSpace: 'nowrap' } },
                                    Number(t[1]).toFixed(1),
                                    "%")));
                        }))))),
            React.createElement(CardActions, { style: { margin: '0px 16px' } },
                React.createElement(Button, { size: "small", onClick: () => setJsonDialog(true) }, "Geo JSON"),
                React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true, sx: { marginLeft: '8px' } }),
                React.createElement(Button, { size: "small", color: "error", onClick: () => props.delete(props.field.id) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")))),
        React.createElement(DialogJsonViewer, { token: props.token, url: `/ndvi/get/${props.field.id}`, open: jsonDialog, title: "GeoJson", close: setJsonDialog })));
});
export default NdviInfoPanel;
