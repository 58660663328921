var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ListAndView from './elements/ListAndView';
import AddDialog from './nvdi/Add';
import ScienceIcon from '@mui/icons-material/Science';
import ListNdvi from './nvdi/List';
import OpenStreetMaps from './map/OpenStreetMaps';
import NdviInfoPanel from './nvdi/Ndvi.Info';
import DialogYesNo from './elements/DialodYesNo';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddWithGoogleDialog from './nvdi/AddWithGoogle';
import ValueSelectedPanel from './nvdi/ValueSelectedPanel';
import { useSSE } from 'react-hooks-sse';
import ProcessList from './nvdi/ProcessList';
function usePreviousProgress(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
function Nvdis(_a) {
    var props = __rest(_a, []);
    const maxNameLength = 43;
    const [selectedElement, setSelectedElement] = React.useState(null);
    const [addDialog, setAddDialog] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [addGoogleDialog, setAddWithGoogleDialog] = React.useState(false);
    const [progressItems, setProgressItems] = React.useState([]);
    const prevProgressItems = usePreviousProgress(progressItems);
    const map = React.useRef(null);
    const list = React.useRef(null);
    const filter = React.useRef(null);
    const tasks = useSSE('ndvi', {
        value: []
    });
    React.useEffect(() => {
        var _a;
        if (prevProgressItems) {
            if ((prevProgressItems === null || prevProgressItems === void 0 ? void 0 : prevProgressItems.length) > 0 && tasks.value.length == 0) {
                (_a = list.current) === null || _a === void 0 ? void 0 : _a.update();
            }
        }
        setProgressItems(tasks.value.map((v) => {
            let p = {
                begin: v.info.begin,
                end: v.info.end,
                field: v.info.field
            };
            return p;
        }));
    }, [tasks.value]);
    React.useEffect(() => {
        var _a;
        if (map.current !== null && selectedElement !== null) {
            let latlng = { lat: selectedElement.ndvi_field.longitude_center, lng: selectedElement.ndvi_field.latitude_center };
            (_a = map.current) === null || _a === void 0 ? void 0 : _a.setUrl(`/ndvi/get/${selectedElement.id}`, latlng);
        }
    }, [selectedElement]);
    const deleteListItem = () => {
        if (selectedElement !== null) {
            axios.delete(`/ndvi/delete/${selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                var _a, _b;
                if (responce.data) {
                    if (responce.data.result) {
                        setSelectedElement(null);
                        setDeleteDialog(false);
                        (_a = list.current) === null || _a === void 0 ? void 0 : _a.update();
                        (_b = map.current) === null || _b === void 0 ? void 0 : _b.invalidateSize();
                    }
                }
            });
        }
    };
    const updateListItems = () => {
        var _a;
        (_a = list.current) === null || _a === void 0 ? void 0 : _a.update();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 2, sx: { padding: '0px', flex: '1 1 auto', pt: '20px' } },
            React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", sx: { pl: 3 } },
                React.createElement(Link, { underline: "hover", color: "inherit", href: "/" }, "Agriculture"),
                React.createElement(Typography, { color: "text.primary" }, "NDVI")),
            React.createElement(Divider, null),
            React.createElement(ListAndView, { left: React.createElement(React.Fragment, null,
                    React.createElement("div", { style: {
                            backgroundColor: '#fafafa',
                            height: '52px',
                            borderBottom: '1px solid #eaeaea',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0px 16px'
                        } },
                        React.createElement(Tooltip, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u044D\u043B\u0435\u043C\u0435\u043D\u0442 \u0438\u0437 \u0444\u0430\u0439\u043B\u0430" },
                            React.createElement(IconButton, { onClick: () => setAddDialog(true), "aria-label": "fingerprint", color: "success" },
                                React.createElement(NoteAddIcon, null))),
                        React.createElement(Tooltip, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0438\u0437 Earthengine Google" },
                            React.createElement(IconButton, { "aria-label": "fingerprint", color: "success", onClick: () => setAddWithGoogleDialog(true) },
                                React.createElement(ScienceIcon, null))),
                        React.createElement(Divider, { orientation: 'vertical', flexItem: true, sx: { margin: '0px 8px' } }),
                        React.createElement(Tooltip, { title: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C" },
                            React.createElement(IconButton, { "aria-label": "fingerprint", onClick: () => updateListItems() },
                                React.createElement(RefreshIcon, null)))),
                    React.createElement(Box, { sx: {
                            height: 'calc(100vh - 180px)',
                            overflowY: 'scroll',
                            "&::-webkit-scrollbar": {
                                width: 8
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#F1F1F1"
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#C1C1C1",
                                borderRadius: 2
                            }
                        } },
                        React.createElement(ProcessList, { items: progressItems }),
                        React.createElement(ListNdvi, { ref: list, token: props.token, select: setSelectedElement }))), view: React.createElement(Grid, { container: true, spacing: 0, sx: { marginTop: '0px !important', height: '100%' } },
                    selectedElement !== null
                        &&
                            (React.createElement(Grid, { item: true, xs: "auto", style: { display: 'flex' } },
                                React.createElement(NdviInfoPanel, { token: props.token, field: selectedElement, filterPanel: filter, delete: (x) => setDeleteDialog(true) }))),
                    React.createElement(Grid, { item: true, xs: true },
                        React.createElement(OpenStreetMaps, { token: props.token, ref: map, url: `/ndvi/get/${selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.id}`, draw: true, createBoundExtend: true, customControl: React.createElement(ValueSelectedPanel, { ref: filter, map: map }), item: selectedElement === null ? 0 : selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.id }))) })),
        React.createElement(AddDialog, { token: props.token, open: addDialog, maxLength: maxNameLength, update: updateListItems, setState: setAddDialog }),
        React.createElement(AddWithGoogleDialog, { token: props.token, open: addGoogleDialog, update: updateListItems, setState: setAddWithGoogleDialog }),
        React.createElement(DialogYesNo, { title: '\u0423\u0434\u0430\u043B\u0435\u043D\u0438\u0435', question: `Вы хотите удалить ${selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.ndvi_name} без возможности восстановления?`, open: deleteDialog, yes: () => deleteListItem(), no: setDeleteDialog })));
}
export default Nvdis;
