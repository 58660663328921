import { ThemeProvider } from '@mui/material';
import React, { useImperativeHandle, forwardRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export class VariantItem {
}
const Variants = forwardRef((props, ref) => {
    var _a;
    const [value, setValue] = React.useState(((_a = props.items.filter(x => x.disabled == false)[0]) === null || _a === void 0 ? void 0 : _a.index) || 0);
    const handleChange = (event) => {
        let item = Number(event.target.value);
        setValue(item);
        props.change(item);
    };
    useImperativeHandle(ref, () => ({
        setValue: setValue
    }));
    return (React.createElement(ThemeProvider, { theme: props.theme },
        React.createElement(FormControl, { sx: { m: '8px 0px', width: '100%' }, size: "small" },
            React.createElement(Select, { value: value, onChange: handleChange }, props.items.map((v, i) => {
                return (React.createElement(MenuItem, { disabled: v.disabled, selected: value == v.index, value: v.index }, v.title));
            })))));
});
export default Variants;
