var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ru';
import * as dayjs from 'dayjs';
export default function DateFiled(_a) {
    var props = __rest(_a, []);
    const disableWeekends = (date) => {
        if (!props.min)
            return false;
        return date < dayjs(props.min) || date > dayjs(props.max);
    };
    return (React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: 'ru' },
        React.createElement(DatePicker, { label: props.label ? props.label : "Дата", value: props.value, onChange: (newValue) => {
                props.setValue(newValue);
            }, disabled: props.disabled ? props.disabled : false, shouldDisableDate: disableWeekends, renderInput: (params) => React.createElement(TextField, Object.assign({ margin: "normal", sx: { marginTop: '16px !important', width: '100%' }, variant: "filled" }, params)) })));
}
