import React, { forwardRef, useImperativeHandle } from 'react';
import { Avatar, Divider, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import axios from 'axios';
import * as dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
const ListField = forwardRef((props, ref) => {
    const [list, setList] = React.useState([]);
    const [listGroup, setListGroup] = React.useState([]);
    const [current, setCurrent] = React.useState(null);
    const [load, setLoad] = React.useState(false);
    const gruppedFormat = 'YYYY-MM-DD';
    useImperativeHandle(ref, () => ({
        update: update
    }));
    React.useEffect(() => {
        update();
    }, []);
    React.useEffect(() => {
    }, [props.progressItems]);
    const update = () => {
        if (props.token) {
            setLoad(true);
            axios.get('/field/list', {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let items = responce.data.map((i) => {
                        i.date_created = new Date(i.date_created);
                        return i;
                    });
                    let groups = items.map((i) => {
                        return dayjs(i.date_created).format(gruppedFormat);
                    });
                    setListGroup([...new Set(groups)]);
                    setList(items);
                    setLoad(false);
                }
            });
        }
    };
    React.useEffect(() => {
        props.select(current);
    }, [current]);
    return (React.createElement(React.Fragment, null,
        load == true && (React.createElement(React.Fragment, null,
            React.createElement(LinearProgress, { color: "success" }),
            listGroup.length == 0 && (React.createElement(Typography, { variant: 'caption', component: 'div', align: 'center', style: { color: 'grey', paddingTop: '16px' } }, "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u0441\u043F\u0438\u0441\u043A\u0430 ...")))),
        listGroup.map((g, li) => {
            return (React.createElement(List, { sx: { width: '100%', maxWidth: 360, bgcolor: 'background.paper' }, key: `li_${li}`, component: "nav", subheader: React.createElement(ListSubheader, { component: "div", id: "nested-list-subheader" }, dayjs(g).format('ll')) }, list.filter(x => dayjs(x.date_created).format(gruppedFormat) === g)
                .map((item) => (React.createElement("div", { key: `cont_${item.id}` },
                React.createElement(ListItem, { key: `l_${item.id}`, disablePadding: true },
                    React.createElement(ListItemButton, { disabled: props.progressItems.filter(x => x == item.id).length > 0, selected: (current === null || current === void 0 ? void 0 : current.id) == item.id, onClick: () => setCurrent(item) },
                        React.createElement(ListItemAvatar, null,
                            React.createElement(Avatar, { src: `/field/image/${item.id}`, alt: item.name, sx: { backgroundColor: '#bdbdbd' }, imgProps: {
                                    style: {
                                        width: '28px',
                                        height: '28px'
                                    }
                                } })),
                        React.createElement(ListItemText, { primary: item.name.charAt(0).toUpperCase() + item.name.slice(1), secondary: props.progressItems.filter(x => x == item.id).length === 0
                                ?
                                    `Создано: ${dayjs(item.date_created).format('lll')}`
                                :
                                    React.createElement(LinearProgress, { variant: 'indeterminate', sx: { margin: '8px 0px' } }) }),
                        !item.plot_count && props.showWarning == true && (React.createElement(PriorityHighIcon, { color: 'warning', fontSize: 'small', sx: {
                                position: 'absolute',
                                left: '44px',
                                bottom: '17px',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                fontSize: '0.9rem'
                            } })))),
                React.createElement(Divider, { key: `d_${item.id}`, variant: "fullWidth", component: "li" }))))));
        })));
});
export default ListField;
