import L from "leaflet";
import { GeoJSON, useMap, Polygon, CircleMarker } from "react-leaflet";
import axios from "axios";
import React, { forwardRef, useImperativeHandle } from 'react';
import * as turf from '@turf/turf';
const GeoJson = forwardRef((props, ref) => {
    const [data, setData] = React.useState(null);
    const [center, setCenter] = React.useState({ lat: 49.9714, lng: 82.6059 });
    const [geometryBound, setGeometryBound] = React.useState(null);
    const map = useMap();
    const ref_geo = React.useRef(null);
    useImperativeHandle(ref, () => ({
        changeUrl: get_data
    }));
    React.useEffect(() => {
        get_data(props.url, props.token, { lat: 49.9714, lng: 82.6059 });
    }, []);
    const get_data = (url, token, center) => {
        props.setLoad(true);
        setData(null);
        setCenter(center);
        if (url && token) {
            axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    setData(responce.data);
                    if (props.createBoundExtend) {
                        const allPolygons = responce.data.features
                            .filter((feature) => feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon');
                        try {
                            const allPolygonGeometries = allPolygons.map((polygon) => polygon.geometry);
                            const unioned = allPolygonGeometries.length > 1 ? allPolygonGeometries.reduce((acc, curr) => {
                                return turf.union(acc, curr);
                            }) : turf.feature(allPolygonGeometries[0]);
                            setGeometryBound(unioned.geometry);
                        }
                        catch (_a) {
                            console.error("Bounds error");
                        }
                    }
                }
                props.setLoad(false);
            })
                .catch(() => {
                props.setLoad(false);
            });
        }
    };
    const get_polygon = (coords) => {
        return coords.map((coords) => {
            return {
                lat: coords[1],
                lng: coords[0]
            };
        });
    };
    const get_color = (value, inner, isgrey = false) => {
        let options = {
            color: '#2e7d32',
            stroke: false,
            fillOpacity: .6,
            strokeWidth: 0
        };
        if (isgrey == true) {
            options.color = 'white';
            return options;
        }
        if (inner.fill !== undefined) {
            options.color = inner.fill;
            if (inner.fill == 'white'
                || (value == 100 && props.scipBorder == undefined)) {
                options.color = 'white';
                options.stroke = true;
                options.fillOpacity = 0;
                options.strokeWidth = 1;
            }
        }
        else {
            if (value <= 0.1) {
                options.color = '#d32f2f';
            }
            else if (value > 0.1 && value <= 0.2) {
                options.color = '#ef6c00';
            }
            else if (value > 0.2 && value < 0.39) {
                options.color = '#827717';
            }
            else if (value > 0.39 && value < 0.59) {
                options.color = '#689f38';
            }
            else if (value == 100) {
                options.color = 'white';
                options.stroke = true;
                options.fillOpacity = 0;
                options.strokeWidth = 1;
            }
        }
        return options;
    };
    if (data) {
        const geojsonObject = L.geoJSON(data);
        let bounds = geojsonObject.getBounds();
        let expression = {
            padding: {
                x: 30, y: 30
            },
            maxZoom: 17
        };
        map.flyToBounds(bounds, expression);
    }
    return (React.createElement(React.Fragment, null, data
        &&
            (React.createElement(React.Fragment, null,
                geometryBound && (React.createElement(Polygon, { positions: get_polygon(geometryBound.coordinates[0]), pathOptions: {
                        color: 'white',
                        stroke: true,
                        fillOpacity: 0,
                        strokeWidth: 1
                    } })),
                props.draw ? (data.features
                    .filter(f => f.geometry !== null)
                    .map((f, index) => {
                    var _a, _b;
                    let isgrey = false;
                    if (props.filterId && props.filterId.length > 0) {
                        isgrey = !((_a = props.filterId) === null || _a === void 0 ? void 0 : _a.includes((_b = (f.id || '')) === null || _b === void 0 ? void 0 : _b.toString()));
                    }
                    let options = undefined;
                    if (f.id) {
                        options = get_color(f.id, f.properties, isgrey);
                    }
                    let poligons = [];
                    if (f.geometry) {
                        if (f.geometry.type == "Polygon") {
                            poligons = get_polygon(f.geometry.coordinates[0]);
                        }
                        else if (f.geometry.type == "MultiPolygon") {
                            poligons = f.geometry.coordinates.map((poligon) => {
                                return poligon.map((inner) => {
                                    return get_polygon(inner);
                                });
                            });
                        }
                    }
                    if (f.geometry.type == "Polygon" || f.geometry.type == "MultiPolygon") {
                        return options !== undefined
                            ?
                                React.createElement(Polygon, { key: `polygon_${index}`, pathOptions: options, positions: poligons })
                            :
                                React.createElement(Polygon, { key: `polygon_${index}`, positions: poligons });
                    }
                    else if (f.geometry.type == "Point") {
                        return React.createElement(CircleMarker, { center: [f.geometry.coordinates[1], f.geometry.coordinates[0]], radius: 5, stroke: false, color: "white", fillOpacity: 1 });
                    }
                }))
                    : (React.createElement(GeoJSON, { ref: ref_geo, data: data, style: {
                            color: data.features.filter(x => x.geometry.type == 'LineString') ? '#ffc107' : 'white',
                            fillColor: '#ffc107',
                            stroke: true,
                            fillOpacity: 0.3
                        } }))))));
});
export default GeoJson;
