var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box, Divider, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ListAndView from './elements/ListAndView';
import ListField from './field/List';
import RefreshIcon from '@mui/icons-material/Refresh';
import ChartTabs from './statistic/ChartTabs';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DialogYesNo from './elements/DialodYesNo';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSSE } from 'react-hooks-sse';
function Statistics(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const [field, setField] = React.useState(null);
    const [buildDialog, setBuildDialog] = React.useState(false);
    const [progress, setProgress] = React.useState([]);
    const [alhorMore, setAlhorMore] = React.useState(null);
    const list = React.useRef(null);
    const tasks = useSSE('tasks', {
        value: []
    });
    React.useEffect(() => {
        setProgress(tasks.value.map(x => Number(x._id.replace("history:", ""))));
    }, [tasks.value]);
    const more_click = (event) => {
        setAlhorMore(event.currentTarget);
    };
    const more_close = () => {
        setAlhorMore(null);
    };
    const report_download = (url) => {
        more_close();
        window.location.href = url;
    };
    const rebuild = () => {
        if (props.token && field) {
            setField(null);
            axios.get(`/statistic/calc/${field === null || field === void 0 ? void 0 : field.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    enqueueSnackbar('Процесс построения отчета запущен', { variant: 'success', autoHideDuration: 1000 });
                    setBuildDialog(false);
                }
            })
                .catch(function (reason) {
                let error = reason.response.data.detail || reason.message;
                enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                setBuildDialog(false);
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 2, sx: { padding: '0px', flex: '1 1 auto', pt: '20px' } },
            React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", sx: { pl: 3 } },
                React.createElement(Link, { underline: "hover", color: "inherit", href: "/" }, "Agriculture"),
                React.createElement(Typography, { color: "text.primary" }, "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430")),
            React.createElement(Divider, null),
            React.createElement(ListAndView, { left: React.createElement(React.Fragment, null,
                    React.createElement("div", { style: {
                            backgroundColor: '#fafafa',
                            height: '52px',
                            borderBottom: '1px solid #eaeaea',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0px 16px'
                        } },
                        React.createElement(Tooltip, { title: "\u041F\u0435\u0440\u0435\u0441\u043E\u0431\u0440\u0430\u0442\u044C \u0433\u0440\u0430\u0444\u0438\u043A\u0438" },
                            React.createElement(IconButton, { "aria-label": "fingerprint", color: "success", disabled: field == null || tasks.value.filter(x => x._id == `history:${field === null || field === void 0 ? void 0 : field.id}`).length > 0, onClick: () => setBuildDialog(true) },
                                React.createElement(PlayCircleOutlineIcon, null))),
                        React.createElement(Tooltip, { title: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C" },
                            React.createElement(IconButton, { "aria-label": "fingerprint" },
                                React.createElement(RefreshIcon, null))),
                        React.createElement(Divider, { orientation: 'vertical', flexItem: true, sx: { margin: '0px 8px' } }),
                        React.createElement(IconButton, { disabled: field == null || tasks.value.filter(x => x._id == `history:${field === null || field === void 0 ? void 0 : field.id}`).length > 0, onClick: more_click },
                            React.createElement(MoreVertIcon, null)),
                        React.createElement(Menu, { id: "basic-menu", anchorEl: alhorMore, open: alhorMore !== null, onClose: more_close, MenuListProps: {
                                'aria-labelledby': 'basic-button',
                            } },
                            React.createElement(MenuItem, { onClick: () => report_download(`/statistic/export/drought/${field === null || field === void 0 ? void 0 : field.id}/xlsx`) }, "\u042D\u043A\u0441\u043F\u043E\u0440\u0442 \u0437\u0430\u0441\u0443\u0445\u0438"),
                            React.createElement(MenuItem, { onClick: () => report_download(`/statistic/export/climate/${field === null || field === void 0 ? void 0 : field.id}/xlsx`) }, "\u042D\u043A\u0441\u043F\u043E\u0440\u0442 \u0442\u0435\u043C\u043F\u0435\u0440\u0430\u0442\u0443\u0440\u044B"),
                            React.createElement(MenuItem, { onClick: () => report_download(`/statistic/export/precipitation/${field === null || field === void 0 ? void 0 : field.id}/xlsx`) }, "\u042D\u043A\u0441\u043F\u043E\u0440\u0442 \u043E\u0441\u0430\u0434\u043A\u043E\u0432"))),
                    React.createElement(Box, { sx: {
                            height: 'calc(100vh - 180px)',
                            overflowY: 'scroll',
                            "&::-webkit-scrollbar": {
                                width: 8
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#F1F1F1"
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#C1C1C1",
                                borderRadius: 2
                            }
                        } },
                        React.createElement(ListField, { ref: list, showWarning: false, progressItems: progress, token: props.token, select: setField }))), view: React.createElement(ChartTabs, { token: props.token, field: field, loaded: tasks.value.filter(x => x._id == `history:${field === null || field === void 0 ? void 0 : field.id}`).length > 0 }) })),
        React.createElement(DialogYesNo, { title: '\u0413\u0440\u0430\u0444\u0438\u043A', question: `Вы хотите пересобрать данные и построить графики для поля ${field === null || field === void 0 ? void 0 : field.name} повторно?`, open: buildDialog, yes: () => rebuild(), no: setBuildDialog })));
}
export default Statistics;
