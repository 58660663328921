var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from 'axios';
import React from 'react';
import StorageTable from '../elements/StorageTable';
function DataGrid(_a) {
    var props = __rest(_a, []);
    const [meta, setMeta] = React.useState(null);
    React.useEffect(() => {
        if (props.token) {
            axios.get(`${props.model}/columns`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            }).
                then((responce) => {
                if (responce.data) {
                    setMeta(responce.data);
                }
            });
        }
    }, []);
    return (React.createElement(React.Fragment, null, meta !== null && (React.createElement(React.Fragment, null,
        React.createElement(StorageTable, { meta: meta, token: props.token, title: props.title, model: props.model, prefix: props.prefix })))));
}
export default DataGrid;
