var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack } from '@mui/system';
import { Divider, FormControlLabel, Switch, Typography } from '@mui/material';
import axios from 'axios';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useSnackbar } from 'notistack';
import Password from '../admin/Password';
export default function AutoUploadDialog(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = React.useState(null);
    const [access, setAccess] = React.useState(false);
    const [relationData, setRelationData] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const data = React.useRef([]);
    React.useEffect(() => {
        let urls = props.columns.filter(x => x.relation.length > 0)
            .map((rel) => {
            let promise = {
                url: `/${rel.relation[0]._to}/list?limit=100`,
                get: axios.get(`/${rel.relation[0]._to}/list?limit=100`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${props.token}`
                    }
                }),
                view: rel.relation[0]._view
            };
            return promise;
        });
        if (urls.length > 0) {
            axios.all(urls.map(x => x.get))
                .then(axios.spread((...res) => {
                let items = res.map((config) => {
                    let view = urls.filter(x => x.url === config.config.url);
                    let data_items = 'items' in config.data ? config.data.items : config.data;
                    let r = {
                        url: config.config.url,
                        data: data_items.map((item) => {
                            let value = {
                                value: item.id,
                                name: view[0].view in item ? item[view[0].view] : ''
                            };
                            return value;
                        })
                    };
                    return r;
                });
                setRelationData(items);
            }));
        }
    }, [props.columns]);
    const change = (name, e, checked = null) => {
        let fv = {
            name: name,
            value: e == null ? '' : (checked || e.target.value)
        };
        let fields = data.current.filter(x => x.name !== name);
        fields.push(fv);
        data.current = fields;
        check();
    };
    const open = (name) => {
        let table_name = name.split('_').slice(1, 2)[0];
        if (table_name !== undefined) {
            let index = props.columns.findIndex(x => x.name == name);
            let relations = props.columns.slice(0, index);
            let tables = relations.filter(x => x.name != name && x.relation.length > 0);
            let current = props.columns.filter(x => x.name == name)[0];
            let filter = tables.map((x) => {
                var _a;
                if (data.current) {
                    let values = (_a = data.current) === null || _a === void 0 ? void 0 : _a.filter(y => y.name == x.name);
                    if (values.length > 0) {
                        let _name = [table_name].concat(x.name.split('_').slice(1)).join('_');
                        return `${_name}=${values[0].value}`;
                    }
                }
                return '';
            });
            filter = filter.filter(x => x !== '');
            let url = `/${current.relation[0]._to}/list?limit=100${filter.length > 0 ? '&' : ''}${filter.join('&')}`;
            axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((response) => {
                if (response.data) {
                    let fl = {
                        name: name,
                        value: response.data.items.map((x) => x.id)
                    };
                    setFilterData([fl]);
                }
            });
        }
    };
    const check = () => {
        let names = props.columns.filter(x => x.name !== 'id').map((x) => {
            return x.name;
        });
        let mod = data.current.filter(x => names.includes(x.name) == true && x.value !== '');
        setAccess(mod.length == names.length);
    };
    const upload = () => {
        let data_value = {
            id: "0"
        };
        data.current.forEach((e) => {
            data_value[e.name] = e.value;
        });
        axios.post(`${props.model}/autoupload`, JSON.stringify(data_value), {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.token}`
            }
        })
            .then((responce) => {
            if (responce.data) {
                if (props.refresh) {
                    props.refresh();
                }
                props.setState(false);
                if (responce.data.success == true) {
                    enqueueSnackbar(`Данные успешно загружены (${responce.data.count})`, { variant: 'success' });
                }
                else {
                    enqueueSnackbar(responce.data.error, { variant: 'error' });
                }
            }
        })
            .catch((reason) => {
            let error = reason.response.data.detail || reason.message;
            enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
        });
    };
    const getType = (type) => {
        if (type == 'integer' || type == 'double') {
            return 'number';
        }
        else if (type == 'datetime') {
            return 'datetime-local';
        }
        else if (type == 'date') {
            return 'date';
        }
        else if (type == 'boolean') {
            return 'boolean';
        }
        else {
            return 'text';
        }
    };
    return (React.createElement("div", null,
        React.createElement(Dialog, { maxWidth: 'xs', open: props.open, BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            } },
            React.createElement(DialogTitle, null, `Загрузить в ${props.title}`),
            React.createElement(DialogContent, null,
                React.createElement(Box, { sx: { padding: '16px' } },
                    React.createElement(Typography, { color: "text.secondary", variant: "body2" }, "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u0434\u0430\u043D\u043D\u044B\u0445 \u0438\u0437 \u0432\u043D\u0435\u0448\u043D\u0435\u0433\u043E \u0438\u0441\u0442\u043E\u0447\u043D\u0438\u043A\u0430")),
                React.createElement(Divider, { variant: "middle" }),
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(Stack, { component: "form", sx: {
                            width: 'auto',
                            minWidth: '340px'
                        }, spacing: 2, noValidate: true, autoComplete: "off" }, props.columns.filter(x => x.name !== 'id')
                        .map((column, index) => {
                        let _type = getType(column.type_);
                        if (column.name == 'password') {
                            _type = 'password';
                        }
                        return (column.relation.length == 0
                            ?
                                (React.createElement(React.Fragment, null, _type !== 'boolean'
                                    ?
                                        (React.createElement(React.Fragment, null, _type == 'password'
                                            ?
                                                (React.createElement(Password, { disabled: props.token == null, setPassword: (value) => change(column.name, value) }))
                                            :
                                                (React.createElement(TextField, { disabled: props.token == null, onChange: (e) => change(column.name, e), type: _type, key: `field_${column.name}`, label: column.type_.includes('date') ? '' : column.description.replace(`${props.prefix}_`, ''), helperText: column.type_.includes('date') == false ? '' : column.description.replace(`${props.prefix}_`, ''), id: column.name, variant: "filled" }))))
                                    :
                                        (React.createElement(FormControlLabel, { disabled: props.token == null, key: `field_${column.name}`, control: React.createElement(Switch, { id: column.name, onChange: (e, c) => change(column.name, e, c), name: column.name }), label: column.type_.includes('date') ? '' : column.description.replace(`${props.prefix}_`, '') }))))
                            :
                                (React.createElement(FormControl, { key: `field_for__${column.name}`, fullWidth: true, variant: "filled" },
                                    React.createElement(Select, { labelId: "demo-simple-select-label", id: column.name, key: `field_${column.name}`, onChange: (e) => change(column.name, e), onOpen: e => open(column.name), disabled: props.token == null }, relationData.filter(x => x.url === `/${column.relation[0]._to}/list?limit=100`)
                                        .map((item) => {
                                        return (item.data.map((kv) => {
                                            let disabled = false;
                                            if (filterData.length > 0) {
                                                let filter = filterData.filter(x => x.name == column.name);
                                                if (filter.length > 0) {
                                                    disabled = !filter[0].value.includes(kv.value);
                                                }
                                            }
                                            return (React.createElement(MenuItem, { disabled: disabled, value: kv.value }, kv.name));
                                        }));
                                    })),
                                    React.createElement(FormHelperText, null, column.description.replace(`${props.prefix}_`, '')))));
                    })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => upload(), disabled: !access }, "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C"),
                React.createElement(Button, { onClick: () => props.setState(false) }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
