import React, { useImperativeHandle, forwardRef } from 'react';
import dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
import { Stack, TextField } from '@mui/material';
dayjs.extend(isLeapYear);
dayjs.locale('ru');
const Password = forwardRef((props, ref) => {
    const [value, setValue] = React.useState();
    const [value_check, setValueCheck] = React.useState();
    useImperativeHandle(ref, () => ({}));
    React.useEffect(() => {
        if (value && value_check) {
            if ((value === null || value === void 0 ? void 0 : value.target.value) == (value_check === null || value_check === void 0 ? void 0 : value_check.target.value)) {
                props.setPassword(value);
            }
            else {
                props.setPassword(null);
            }
        }
    }, [value, value_check]);
    return (React.createElement(Stack, { component: "form", sx: {
            width: 'auto',
            minWidth: '340px'
        }, spacing: 2, noValidate: true, autoComplete: "off" },
        React.createElement(TextField, { autoComplete: 'new-password', disabled: props.disabled, onChange: (e) => setValue(e), type: 'password', key: `field_password}`, label: 'Пароль', id: 'password', variant: "filled" }),
        React.createElement(TextField, { autoComplete: 'new-password', disabled: props.disabled, onChange: (e) => setValueCheck(e), type: 'password', key: `field_password-check`, label: 'Повторить пароль', id: 'password-check', variant: "filled", error: (value === null || value === void 0 ? void 0 : value.target.value) != (value_check === null || value_check === void 0 ? void 0 : value_check.target.value), helperText: (value === null || value === void 0 ? void 0 : value.target.value) != (value_check === null || value_check === void 0 ? void 0 : value_check.target.value) ? 'Пароли не совпадают' : '' })));
});
export default Password;
