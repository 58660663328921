var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack } from '@mui/system';
import { Divider, LinearProgress, Typography } from '@mui/material';
import { useUploadForm } from '../../utils/upload';
import { useSnackbar } from 'notistack';
export default function ImportDialog(_a) {
    var props = __rest(_a, []);
    const { enqueueSnackbar } = useSnackbar();
    const { uploadForm, destroyControls, progress, isSuccess } = useUploadForm(`/${props.model}/import`);
    const [fileXls, setFile] = React.useState(null);
    const [stantion, setStantion] = React.useState(1);
    const [error, setError] = React.useState(null);
    const [access, setAccess] = React.useState(false);
    const fileChange = (event) => {
        setFile(event.target.files ? event.target.files[0] : null);
    };
    React.useEffect(() => {
        if (props.model === 'meteo') {
            setAccess(fileXls !== null && stantion > 0);
        }
        else {
            setAccess(fileXls !== null);
        }
    }, [fileXls]);
    const import_file = () => __awaiter(this, void 0, void 0, function* () {
        if (fileXls !== null) {
            const formData = new FormData();
            formData.append("xls", fileXls);
            if (props.model === 'meteo') {
                formData.append("stantion", stantion.toString());
            }
            yield uploadForm(props.token, formData)
                .then(function (response) {
                if (response.data.error !== null) {
                    setError(response.data.error);
                    alert(response.data.error);
                    destroy();
                    let error = response.data.error;
                    enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
                }
                else {
                    destroy();
                    props.setState(false);
                    if (props.refresh) {
                        props.refresh();
                    }
                    enqueueSnackbar('Успешный импорт', { variant: 'success', autoHideDuration: 1000 });
                }
            })
                .catch(function (reason) {
                let error = reason.response.data.detail || reason.message;
                setError(error);
                enqueueSnackbar(error.length > 100 ? `${error.substring(0, 97)}...` : error, { variant: 'error' });
            });
        }
    });
    const destroy = () => __awaiter(this, void 0, void 0, function* () {
        setFile(null);
        setError(null);
        destroyControls();
    });
    return (React.createElement("div", null,
        React.createElement(Dialog, { maxWidth: 'xs', open: props.open, BackdropProps: {
                style: {
                    backdropFilter: "blur(3px)"
                }
            } },
            React.createElement(DialogTitle, null, `Импорт в ${props.title}`),
            React.createElement(DialogContent, null,
                React.createElement(Box, { sx: { padding: '16px' } },
                    React.createElement(Typography, { color: "text.secondary", variant: "body2" }, "\u0418\u043C\u043F\u043E\u0440\u0442 \u0434\u0430\u043D\u043D\u044B\u0445 \u2014 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0434\u0430\u043D\u043D\u044B\u0445, \u0432\u0441\u0442\u0430\u0432\u043A\u0430 \u0434\u0430\u043D\u043D\u044B\u0445 \u0438\u0437 \u0432\u043D\u0435\u0448\u043D\u0438\u0445 \u0438\u0441\u0442\u043E\u0447\u043D\u0438\u043A\u043E\u0432 \u0432 \u0431\u0430\u0437\u0443 \u0434\u0430\u043D\u043D\u044B\u0445.")),
                React.createElement(Divider, { variant: "middle" }),
                React.createElement(Stack, { spacing: 2, direction: 'column', sx: { padding: '0px 16px', marginTop: '16px' } },
                    React.createElement(Typography, { variant: 'body2' }, props.meta.accept == '.csv' && ('Разделитилем для формата CSV должно быть ; (точка с запятой)')),
                    props.model === 'meteo' && (React.createElement(TextField, { margin: "dense", id: "stantion", label: "\u0421\u0442\u0430\u043D\u0446\u0438\u044F", fullWidth: true, type: "number", variant: "filled", onChange: (e) => setStantion(Number(e.target.value)) })),
                    React.createElement(Box, { component: "span", sx: { p: 2, border: '1px dashed grey', display: 'flex', justifyContent: 'center' } },
                        React.createElement(Stack, { spacing: 2 },
                            React.createElement(Button, { variant: "text", component: "label" },
                                "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0444\u0430\u0439\u043B",
                                React.createElement("input", { hidden: true, accept: props.meta.accept, multiple: false, type: "file", onChange: fileChange })),
                            React.createElement(Typography, { color: "text.secondary", variant: "body2", sx: { textAlign: 'center' } }, fileXls !== null ? fileXls.name : 'Файл не выбран'))),
                    progress > 0 && access == true && (React.createElement(LinearProgress, { variant: "determinate", value: progress })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: import_file, disabled: !access || progress > 0 }, "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C"),
                React.createElement(Button, { onClick: () => props.setState(false), disabled: progress > 0 }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
}
