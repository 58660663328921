var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Stack, Typography } from '@mui/material';
import React from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from 'axios';
import TabElement from '../elements/TabElement';
import ChartContainer from './ChartContainer';
import TerrainIcon from '@mui/icons-material/Terrain';
function ChartTabs(_a) {
    var props = __rest(_a, []);
    const [value, setValue] = React.useState(0);
    const [tabs, setTabs] = React.useState([]);
    const [pages, setPages] = React.useState([]);
    React.useEffect(() => {
        var _a;
        if (props.token && props.field) {
            axios.get(`/statistic/get/${(_a = props.field) === null || _a === void 0 ? void 0 : _a.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.token}`
                }
            })
                .then((responce) => {
                if (responce.data) {
                    let list = responce.data;
                    list.sort((a, b) => {
                        if (a < b) {
                            return 1;
                        }
                        if (a > b) {
                            return -1;
                        }
                        return 0;
                    });
                    let tabs = [];
                    if (list.some(v => v.includes('pdsi_'))) {
                        tabs.push({
                            active: true,
                            index: 0,
                            model: 'PDSI',
                            prefix: 'pdsi',
                            title: 'Интенсивность засухи'
                        });
                    }
                    if (list.some(v => v.includes('temperature_') || v.includes('precipitation_'))) {
                        tabs.push({
                            active: true,
                            index: 1,
                            model: 'TEMP',
                            prefix: 'temp',
                            title: 'Исторический климат'
                        });
                    }
                    setTabs(tabs);
                    setPages(list);
                }
            });
        }
        else {
            setTabs([]);
            setPages([]);
        }
    }, [props.field, props.loaded]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 2, sx: { padding: '0px', flex: '1 1 auto' } },
            React.createElement(Box, { sx: { maxWidth: { marginTop: '0px !important' }, bgcolor: 'background.paper' } },
                React.createElement(Tabs, { value: value, onChange: handleChange, variant: "scrollable", scrollButtons: true, "aria-label": "visible arrows tabs example", sx: {
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                        borderBottom: '1px solid #eee',
                        minHeight: '52px'
                    } }, (props.loaded == true ? [] : tabs).map((t, index) => {
                    return (React.createElement(Tab, { key: `tab_${index}`, label: t.title, disabled: !t.active }));
                }))),
            props.loaded == false && (React.createElement(React.Fragment, null, tabs.map((t, index) => {
                return (React.createElement(TabElement, { key: `tab_panel_${index}`, value: value, index: index },
                    React.createElement(React.Fragment, null,
                        t.prefix == 'pdsi' && (React.createElement(ChartContainer, { token: props.token, chart: {
                                title: t.title,
                                pages: pages.filter(x => x.includes('pdsi_'))
                            } })),
                        t.prefix == 'temp' && (React.createElement(ChartContainer, { token: props.token, chart: {
                                title: t.title,
                                pages: pages.filter(x => !x.includes('pdsi_'))
                            } })))));
            }))),
            props.field == null && (React.createElement(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    height: 'calc(100vh - 250px)',
                    flexDirection: 'column'
                } },
                React.createElement(Box, { sx: { margin: '0 auto', border: "1px solid lightgray", borderRadius: "50%", padding: "24px", marginBottom: '24px' } },
                    React.createElement(TerrainIcon, { sx: { width: 150, height: 150, color: '#D8D8D8' } })),
                React.createElement(Typography, { sx: { margin: '0 auto', color: 'rgba(0, 0, 0, 0.6)' }, variant: "subtitle1" }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u044D\u043B\u0435\u043C\u0435\u043D\u0442 \u0432 \u0441\u043F\u0438\u0441\u043A\u0435 \u0434\u043B\u044F \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F \u0433\u0440\u0430\u0444\u0438\u043A\u043E\u0432"),
                React.createElement(Typography, { sx: { margin: '0 auto', color: 'rgba(0, 0, 0, 0.6)', maxWidth: '500px', textAlign: 'center', marginTop: '8px', fontSize: '0.8rem' }, variant: "body2" }, "\u0414\u0430\u043D\u043D\u044B\u0435 \u0432\u0437\u044F\u0442\u044B \u0437\u0430 \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0438\u0435 10 \u043B\u0435\u0442 \u0438\u0437 \u043A\u043E\u043B\u043B\u0435\u043A\u0446\u0438\u0438 TerraClimate \u2014 \u044D\u0442\u043E \u043D\u0430\u0431\u043E\u0440 \u0434\u0430\u043D\u043D\u044B\u0445 \u0435\u0436\u0435\u043C\u0435\u0441\u044F\u0447\u043D\u043E\u0433\u043E \u043A\u043B\u0438\u043C\u0430\u0442\u0430 \u0438 \u043A\u043B\u0438\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u043E\u0433\u043E \u0432\u043E\u0434\u043D\u043E\u0433\u043E \u0431\u0430\u043B\u0430\u043D\u0441\u0430 \u0434\u043B\u044F \u0433\u043B\u043E\u0431\u0430\u043B\u044C\u043D\u044B\u0445 \u0437\u0435\u043C\u043D\u044B\u0445 \u043F\u043E\u0432\u0435\u0440\u0445\u043D\u043E\u0441\u0442\u0435\u0439. \u041E\u043D \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0435\u0442 \u0438\u043D\u0442\u0435\u0440\u043F\u043E\u043B\u044F\u0446\u0438\u044E \u0441 \u043A\u043B\u0438\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u043E\u0439 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u043E\u0439, \u0441\u043E\u0447\u0435\u0442\u0430\u044F \u043A\u043B\u0438\u043C\u0430\u0442\u043E\u043B\u043E\u0433\u0438\u0447\u0435\u0441\u043A\u0438\u0435 \u043D\u043E\u0440\u043C\u044B \u0441 \u0432\u044B\u0441\u043E\u043A\u0438\u043C \u043F\u0440\u043E\u0441\u0442\u0440\u0430\u043D\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u043C \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043D\u0438\u0435\u043C."))))));
}
export default ChartTabs;
